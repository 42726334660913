<div [class.mobile]="isMobile" [class]="theme"
    class="header-container z-[19] flex items-center justify-between w-full bg-transparent px-[20px]">
    <div class="flex items-center w-1/2 h-full">
        <img [hidden]="isSchool" [src]="logoPath" class="logo" alt="logo">

        <div *ngIf="isTeacher" class="flex items-center h-full ml-4 timer-container" (mouseenter)="togglePopup(true)"
            (mouseleave)="togglePopup(false)">
            <div *ngIf="showTimer"
                class="flex justify-center px-1 text-center align-middle border rounded-sm timer-button timer-text"
                [ngStyle]="{
                    'color': theme.iconColor === 'dark' ? '#151515' : 'white', 
                    'opacity': isTimingOut || !isTiming ? '0.50' : '1', 
                    'border-color': theme.iconColor === 'dark' ? '#151515' : 'white'
                    }">
                {{time}}
            </div>
            <span [ngStyle]="{'color': theme.iconColor === 'dark' ? '#151515' : 'white'}"
                [style.opacity]="showTimer ? '0' : '1'"
                class="w-[48px] text-center material-symbols-outlined text-[20px] cursor-pointer">
                timer
            </span>
            <ul *ngIf="popup" [style.top]="headerHeight + 'px'" class="absolute text-center text-black bg-white popup">
                <li class="timer-menu-item" (click)="toggleTimerEvent()">
                    {{ (showTimer ? 'buttons.hide' : 'buttons.show') | translate }}
                </li>
                <li *ngIf="isTeacher || isSchool" class=" hover:bg-pink-50 timer-menu-item"
                    (click)="onClickResetTimer()">
                    {{'buttons.reset' | translate}}
                </li>
            </ul>
        </div>

    </div>
    <div class="flex items-center justify-end w-1/2">
        <div *ngIf="subrole == 3 && betaTesting" class="mr-2 mt-2">
            <mat-icon matTooltip="{{'lessons.new-reg-booking' | translate}}" (click)=createRegularSession()
                fontSet="material-symbols-sharp">calendar_add_on
            </mat-icon>

        </div>
        <button *ngIf="isFullscreen" (click)="onExitFullscreen()"
            [ngStyle]="{'border-color': theme.iconColor === 'dark' ? '#151515' : 'white', 'color': theme.iconColor === 'dark' ? '#151515' : 'white'}"
            class="exit-fullscreen align-middle  flex items-center justify-center transition-colors border-[1px] py-0.5 px-1 mr-4 border-solid rounded-sm whitespace-nowrap hover:bg-btn-grey-hover">
            {{'buttons.exit-fullscreen' | translate}}
            <mat-icon [color]="theme === 'light' ? '#151515' : 'white'" class="ml-1.5"
                svgIcon="exit-fullscreen"></mat-icon>
        </button>
        <app-speed-test [theme]="theme" [isMobile]="isMobile"></app-speed-test>
    </div>
</div>