<div *ngIf="recordingsList?.length && recordingsList.length > 0; else emptyList" class="mat-container">
	<!--  DESKTOP!!!-->
	@if (!studentId && isStudent) {
		<div class="header mat-container">
			<app-multiteacher-dropdownlist (selectedTeacher)="handleSelectedTeacher($event)" />
			<!-- <h1>{{ "recordings.title" | translate }}</h1> -->
		</div>
		<!-- <h1>{{ "recordings.title" | translate }}</h1> -->
	}
	<table class="desktop" mat-table [dataSource]="dataSource">
		<!-- Title Column -->
		<ng-container matColumnDef="title">
			<th mat-header-cell *matHeaderCellDef>
				{{ "recordings.table-title" | translate }}
			</th>
			<td mat-cell *matCellDef="let list">
				<span class="download-link" (click)="downloadRecording(list)">{{ list?.title }}</span>
			</td>
		</ng-container>

		<!-- Date / Time Column -->
		<ng-container matColumnDef="date">
			<th mat-header-cell *matHeaderCellDef>
				{{ "recordings.table-date" | translate }}
			</th>
			<td mat-cell *matCellDef="let list">{{ list?.date }}</td>
		</ng-container>

		<!-- Room Column -->
		<ng-container matColumnDef="room">
			<th mat-header-cell *matHeaderCellDef>
				{{ "recordings.table-space" | translate }}
			</th>
			<td mat-cell *matCellDef="let list">{{ list?.room }}</td>
		</ng-container>

		<!-- File size Column -->
		<ng-container matColumnDef="size">
			<th mat-header-cell *matHeaderCellDef>
				{{ "recordings.table-size" | translate }}
			</th>
			<td mat-cell *matCellDef="let list">{{ list?.size }}</td>
		</ng-container>

		<ng-container matColumnDef="download">
			<th mat-header-cell *matHeaderCellDef></th>
			<td mat-cell *matCellDef="let list">
				<span hidden class="download-link" (click)="downloadRecording(list)">Download</span>
			</td>
		</ng-container>
		<ng-container matColumnDef="btn">
			<th mat-header-cell *matHeaderCellDef></th>
			<td mat-cell *matCellDef="let list">
				<div class="btn-container">
					<div class="download-btn" (click)="openDownloadMenuList(list)">
						<mat-icon class="text-indigo" svgIcon="vector-down"></mat-icon>
						<div *ngIf="list?.isDownloadListMenuOpen" class="download-menu-list">
							<span (click)="copyLink(list)">{{ "recordings.copy-link" | translate }}</span>
							<span (click)="openEditNameDialog(list)">{{ "recordings.edit-name" | translate }}</span>
							<span (click)="openDeleteRecordingDialog(list)">{{ "recordings.delete-recording" | translate }}</span>
						</div>
					</div>
				</div>
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
	</table>

	<!--  TABLET!!!-->
	<table class="tablet" mat-table [dataSource]="dataSource">
		<!-- Position Column -->
		<ng-container matColumnDef="title">
			<th *matHeaderCellDef></th>
			<td mat-cell *matCellDef="let list">
				<div class="list-title download-link" (click)="downloadRecording(list)">
					{{ list?.title }}
				</div>
				<div class="list-date">{{ list?.date }}</div>
			</td>
		</ng-container>

		<!-- Name Column -->
		<ng-container matColumnDef="date">
			<th *matHeaderCellDef></th>
			<td style="display: none" mat-cell *matCellDef="let list"></td>
		</ng-container>

		<!-- Weight Column -->
		<ng-container matColumnDef="room">
			<th *matHeaderCellDef></th>
			<td style="display: none" mat-cell *matCellDef="let list"></td>
		</ng-container>

		<!-- Symbol Column -->
		<ng-container matColumnDef="size">
			<th *matHeaderCellDef></th>
			<td mat-cell *matCellDef="let list">
				<div class="list-room">
					<span class="list-room-title">{{ "recordings.table-space" | translate }}: &nbsp;</span><span>{{ list?.room }} </span>
				</div>
				<div class="list-info">
					<span>{{ list?.size }}</span> &nbsp;
					<span hidden class="download-link" (click)="downloadRecording(list)">Download</span>
				</div>
			</td>
		</ng-container>

		<ng-container matColumnDef="download">
			<th *matHeaderCellDef></th>
			<td style="display: none" mat-cell *matCellDef="let list"></td>
		</ng-container>
		<ng-container matColumnDef="btn">
			<th *matHeaderCellDef></th>
			<td mat-cell *matCellDef="let list">
				<div class="btn-container">
					<div class="download-btn" (click)="openDownloadMenuList(list)">
						<mat-icon svgIcon="vector-down"></mat-icon>
						<div *ngIf="list?.isDownloadListMenuOpen" class="download-menu-list">
							<span (click)="copyLink(list)">{{ "recordings.copy-link" | translate }}</span>
							<span (click)="openEditNameDialog(list)">{{ "recordings.edit-name" | translate }}</span>
							<span (click)="openDeleteRecordingDialog(list)">{{ "recordings.delete-recording" | translate }}</span>
						</div>
					</div>
				</div>
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
	</table>

	<!-- Mobile!!!-->
	<table class="mobile" mat-table [dataSource]="dataSource">
		<!-- Position Column -->
		<ng-container matColumnDef="title">
			<th *matHeaderCellDef></th>
			<td mat-cell *matCellDef="let list"></td>
		</ng-container>

		<!-- Name Column -->
		<ng-container matColumnDef="date">
			<th *matHeaderCellDef></th>
			<td mat-cell *matCellDef="let list"></td>
		</ng-container>

		<!-- Weight Column -->
		<ng-container matColumnDef="room">
			<th *matHeaderCellDef></th>
			<td mat-cell *matCellDef="let list"></td>
		</ng-container>

		<!-- Symbol Column -->
		<ng-container matColumnDef="size">
			<th *matHeaderCellDef></th>
			<td mat-cell *matCellDef="let list"></td>
		</ng-container>

		<ng-container matColumnDef="download">
			<th *matHeaderCellDef></th>
			<td mat-cell *matCellDef="let list"></td>
		</ng-container>

		<ng-container matColumnDef="btn">
			<th *matHeaderCellDef></th>
			<td class="show" mat-cell *matCellDef="let list">
				<div class="list-title download-link" (click)="downloadRecording(list)">
					{{ list?.title }}
				</div>
				<div class="list-date">
					{{ list?.date }}&nbsp; <span class="list-room-title">{{ "recordings.table-space" | translate }}: &nbsp;</span
					><span class="list-room-name">{{ list?.room }} </span>
				</div>
				<div class="size-container">
					<div class="list-info">
						<span class="list-info-size">{{ list?.size }}</span
						><span hidden class="download-link" (click)="downloadRecording(list)">Download</span>
					</div>
					<div class="btn-container">
						<div class="download-btn" (click)="openDownloadMenuList(list)">
							<mat-icon svgIcon="vector-down"></mat-icon>
							<div *ngIf="list?.isDownloadListMenuOpen" class="download-menu-list">
								<span (click)="copyLink(list)">{{ "recordings.copy-link" | translate }}</span>
								<span (click)="openEditNameDialog(list)">{{ "recordings.edit-name" | translate }}</span>
								<span (click)="openDeleteRecordingDialog(list)">{{ "recordings.delete-recording" | translate }}</span>
							</div>
						</div>
					</div>
				</div>
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
	</table>

	<mat-paginator
		[ngStyle]="{ display: size > 10 ? 'block' : 'none' }"
		#paginator
		[hidePageSize]="true"
		[pageSize]="10"
		[length]="size"
		(page)="pageChanged($event)"
		showFirstLastButtons
		aria-label="Select page of recordings elements"
	>
	</mat-paginator>
</div>
<ng-template #emptyList>
	<div class="empty-list-container">
		<span class="empty-list-text" [innerHTML]="'recordings.empty-list' | translate | safeHtml" appRouteTransformer></span>
	</div>
</ng-template>
