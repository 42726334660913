<!-- <h1> -->
<!-- {{ "lessons.title" | translate }} -->
<!-- </h1> -->
<div class="w-full bg-white rounded-[8px] main-container" [class.mb-16]="tab !== 1">
	@if ((subrole === 6 || subrole === 7) && lessonPackageStatus && showRequestLesson) {
		<div class="header-page-section">
			<app-multiteacher-dropdownlist (selectedTeacher)="handleSelectedTeacher($event)" [allTeachers]="true" />
			<button (click)="showAvailabilityCalendar()" class="schedule-btn ml-auto block">
				{{ "lessons.request-lesson" | translate }}
			</button>
		</div>
	}

	<div class="header w-full flex justify-between max-sm:flex-col-reverse max-sm:items-end border-sirius-black border-b">
		<div class="tab-menu flex max-sm:w-full">
			<div
				data-testid="lessons-tab"
				class="tab-menu-item max-sm:flex-1 justify-center"
				(click)="setTab(0)"
				[class.selected]="tab === 0"
			>
				@if (subrole === 6 && teacherName) {
					{{ "lessons.lessons-with-teacher" | translate }}<span class="text-indigo">&nbsp;{{ teacherName }}</span>
				} @else {
					{{ "menu.lessons" | translate }}
				}
			</div>
			<ng-container *ngIf="isBetaTester">
				<div
					data-testid="calendar-tab"
					class="tab-menu-item max-sm:flex-1 justify-center"
					(click)="setTab(1)"
					[class.selected]="tab === 1"
				>
					{{ "menu.calendar" | translate }}
				</div>
				<div
					*accessControl="Resources.STATISTICS"
					data-testid="statistics-tab"
					class="tab-menu-item max-sm:flex-1 justify-center"
					(click)="setTab(2)"
					[class.selected]="tab === 2"
				>
					{{ "menu.statistics" | translate }}
				</div>
			</ng-container>
		</div>

		<div class="header-buttons">
			<ng-container *ngIf="isBetaTester">
				<button
					*accessControl="Resources.STUDENTS_LIST"
					class="schedule-btn"
					data-testid="schedule-lesson-button"
					(click)="createRegularSession()"
				>
					+ {{ "lessons.new-reg-btn" | translate }}
				</button>
			</ng-container>
		</div>
	</div>
	<app-lessons
		[selectedTeacher]="selectedTeacher"
		(onStartSession)="startSession($event)"
		(onOpenMoreLessonInfoModal)="openMoreLessonInfoModal($event)"
		*ngIf="tab === 0"
	></app-lessons>
	<app-calendar (onStartSession)="startSession($event)" *ngIf="tab === 1"></app-calendar>
	<app-lesson-statistics *ngIf="tab === 2"></app-lesson-statistics>
</div>
