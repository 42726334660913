<div class="w-full">
	<div class="flex justify-between w-full header">
		<div class="bg-transparent logo-outer">
			<a><img alt="Sirius" class="logo" src="assets/icons/sirius/Sirius.png" /></a>
		</div>
		<div class="flex align-middle pr-8 mt-4">
			<button class="flex items-center" (click)="logOut()">
				<span class="text-[14px]">
					{{ "buttons.logout" | translate }}
				</span>
				<img src="../../assets/icons/common/logout.svg" alt="" class="ml-2 h-[20px]" />
			</button>
		</div>
		<app-language-selector />
	</div>
	<div *ngIf="showReviewPage()" class="ob-body m-auto">
		<div class="ob-panel p-2 max-w-sm">
			<h1 class="text-headline-1 font-bold"> {{ "teacher-onboarding.thankyou.title" | translate }}
			</h1>
			<br>
			<div> {{ "teacher-onboarding.thankyou.subtitle" | translate }}
			</div>
			<br>
			<div [innerHTML]="'teacher-onboarding.thankyou.support-message' | translate | safeHtml"></div>
		</div>
	</div>

	<div *ngIf="!showReviewPage()" class="ob-body m-auto">
		<div class="progress-bar max-sm:flex-wrap mb-4">
			<div *ngFor="let step of onboardingSteps; let i = index" (click)="moveToStep(i)" class="progress-step mb-4" [ngClass]="[formSection === i ? 'focused' : '', isClickable(i) ? 'clickable' : '']">
				<div class="text-sm progress-title">
					{{ "teacher-onboarding.progress-bar." + step | translate }}
				</div>
				<div class="progress-line"></div>
			</div>
		</div>
		<div class="ob-panel">
			<form [ngSwitch]="formSection" [formGroup]="form">
				<!-- about -->
				<ng-container *ngSwitchCase="0" formGroupName="about">
					<div class="font-bold text-lg mb-2">
						{{ "teacher-onboarding.about.title" | translate }}
					</div>
					<div class="mb-6">
						{{ "teacher-onboarding.about.subtitle" | translate }}
					</div>
					<section class="mb-2">
						<div class="font-bold mb-px">
							{{ "teacher-onboarding.gender.title" | translate }}
						</div>
						<div>
							<mat-form-field>
								<mat-select formControlName="gender" [placeholder]="'teacher-onboarding.gender.placeholder' | translate">
									<mat-option *ngFor="let g of gendersList" [value]="g.value">
										{{ g.title | translate }}
									</mat-option>
								</mat-select>
								<mat-error *ngIf="isRequiredError('about.gender')">
									{{ "teacher-onboarding.required-error" | translate }}
								</mat-error>
							</mat-form-field>
						</div>
						<div>
							<mat-form-field [hidden]="!isOtherGender">
								<input matInput formControlName="otherGender" [placeholder]="'teacher-onboarding.gender.placeholder2' | translate" type="text" />
							</mat-form-field>
						</div>
					</section>
					<section>
						<div class="font-bold mb-px">
							{{ "teacher-onboarding.country-of-birth.title" | translate }}
						</div>
						<mat-form-field>
							<mat-select formControlName="country" [placeholder]="'teacher-onboarding.country-of-birth.placeholder' | translate">
								<mat-option *ngFor="let c of countriesList" [value]="c.eng">
									{{ c.title | translate }}
								</mat-option>
							</mat-select>
							<mat-error *ngIf="isRequiredError('about.gender')">
								{{ "teacher-onboarding.required-error" | translate }}
							</mat-error>
						</mat-form-field>
					</section>
					<section class="mb-2">
						<div class="font-bold mb-px">
							{{ "teacher-onboarding.dob.title" | translate }}
						</div>
						<mat-form-field>
							<input #dobInput matInput formControlName="dob" [matDatepicker]="picker" [placeholder]="'teacher-onboarding.dob.placeholder' | translate" />
							<mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
							<mat-datepicker #picker></mat-datepicker>
							<mat-error *ngIf="dobError() === 'required'">
								{{ "teacher-onboarding.required-error" | translate }}
							</mat-error>
						</mat-form-field>
					</section>
					<section class="mb-2 max-w-full">
						<div formArrayName="spokenLanguages" class="languages-grid max-w-full">
							<div class="font-bold mb-px max-w-full">
								{{ "teacher-onboarding.languages.title" | translate }}
							</div>
							<div class="font-bold mb-px max-w-full">
								{{ "teacher-onboarding.level.title" | translate }}
							</div>
							<div></div>
							<ng-container *ngFor="let languageForm of spokenLanguages.controls; let i = index">
								<ng-container [formGroup]="languageForm">
									<div class="inline-block w-auto max-w-full input-block">
										<mat-form-field class="max-w-full">
											<mat-select class="max-w-full" formControlName="language" [placeholder]="'teacher-onboarding.languages.placeholder' | translate">
												<mat-option *ngFor="let l of languagesList" [value]="l.value">
													{{ l.title | translate }}
												</mat-option>
											</mat-select>
											<mat-error *ngIf="languageForm.get('language').hasError('required')">
												{{ "teacher-onboarding.required-error" | translate }}
											</mat-error>
										</mat-form-field>
									</div>
									<div class="inline-block w-auto input-block">
										<mat-form-field class="max-w-full">
											<mat-select class="max-w-full" formControlName="level" [placeholder]="'teacher-onboarding.level.placeholder' | translate">
												<mat-option *ngFor="let l of levelsList" [value]="l.value">
													{{ l.title | translate }}
												</mat-option>
											</mat-select>
											<mat-error *ngIf="languageForm.get('level').hasError('required')">
												{{ "teacher-onboarding.required-error" | translate }}
											</mat-error>
										</mat-form-field>
									</div>
									<div class="flex justify-center items-center h-[36px]">
										<button *ngIf="i !== 0" (click)="deleteLanguage(i)">
											<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor">
												<path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z" />
											</svg>
										</button>
									</div>
								</ng-container>
							</ng-container>
						</div>
						<button mat-flat-button class="rounded-full mb-4" (click)="addLanguage()">
							<svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" class="inline-flex h-4 w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
								<line x1="12" y1="5" x2="12" y2="19"></line>
								<line x1="5" y1="12" x2="19" y2="12"></line>
							</svg>
							<span>{{ "buttons.add-language" | translate }}</span>
						</button>
					</section>

					<section class="mb-2">
						<div formArrayName="socials" class="socials-grid">
							<div class="font-bold mb-px">
								{{ "teacher-onboarding.socials.title" | translate }}
							</div>
							<div></div>
							<ng-container *ngFor="let socialForm of socials.controls; let i = index">
								<div class="inline-block">
									<mat-form-field>
										<input [placeholder]="'teacher-onboarding.socials.placeholder' | translate" [formControlName]="i" matInput type="text" /></mat-form-field>
								</div>

								<div class="flex justify-center items-center shrink h-[36px]">
									<button *ngIf="i !== 0" (click)="deleteSocial(i)">
										<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor">
											<path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z" />
										</svg>
									</button>
								</div>
							</ng-container>
						</div>
						<button mat-flat-button class="-mt-3 rounded-full mb-4" (click)="addSocial()">
							<svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" class="inline-flex h-4 w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
								<line x1="12" y1="5" x2="12" y2="19"></line>
								<line x1="5" y1="12" x2="19" y2="12"></line>
							</svg>
							<span>{{ "buttons.add-link" | translate }}</span>
						</button>
					</section>

					<section class="mb-4 w-full">
						<div class="mb-px">
							<span class="font-bold">
								{{ "teacher-onboarding.phone.title" | translate }}
							</span>
							<span>
								{{ "teacher-onboarding.phone.subtitle" | translate }}
							</span>
						</div>

						<mat-form-field class="phone-field">
							<input [placeholder]="'teacher-onboarding.phone.placeholder' | translate" matInput formControlName="phoneNumber" type="tel" />
							<mat-error *ngIf="isRequiredError('about.phoneNumber')">
								{{ "teacher-onboarding.required-error" | translate }}
							</mat-error>
						</mat-form-field>
					</section>
					<section class="mb-6">
						<mat-checkbox formControlName="ageConfirmation">{{ "teacher-onboarding.confirm-age.title" | translate }}</mat-checkbox>
						<mat-error class="error-message" *ngIf="isRequiredError('about.ageConfirmation')">
							{{ "teacher-onboarding.required-error" | translate }}
						</mat-error>
					</section>
					<div class="flex justify-end w-full">
						<button mat-flat-button class="s-mat-button" color="accent" (click)="saveGroup(about)">
							{{ "buttons.save-continue" | translate }}
						</button>
					</div>
				</ng-container>

				<!-- photo -->

				<ng-container *ngSwitchCase="1" formGroupName="photo">
					<div class="font-bold text-lg mb-2">
						{{ "teacher-onboarding.photo.title" | translate }}
					</div>
					<div class="mb-6">
						{{ "teacher-onboarding.photo.subtitle" | translate }}
					</div>
					<div class="font-bold mb-1.5">
						{{ "teacher-onboarding.photo.tips-title" | translate }}
					</div>
					<div class="flex h-20 gap-4 mb-2">
						<img src="../../assets/images/profile-photo/example1.png" alt="example photo 1" />
						<img src="../../assets/images/profile-photo/example2.png" alt="example photo 2" />
						<img src="../../assets/images/profile-photo/example3.png" alt="example photo 3" />
					</div>
					<div>
						{{ "teacher-onboarding.photo.tip1" | translate }}
					</div>
					<div>
						{{ "teacher-onboarding.photo.tip2" | translate }}
					</div>
					<div>
						{{ "teacher-onboarding.photo.tip3" | translate }}
					</div>
					<div>
						{{ "teacher-onboarding.photo.tip4" | translate }}
					</div>
					<div>
						{{ "teacher-onboarding.photo.tip5" | translate }}
					</div>
					<div>
						{{ "teacher-onboarding.photo.tip6" | translate }}
					</div>
					<div class="mb-4">
						{{ "teacher-onboarding.photo.tip7" | translate }}
					</div>
					<app-photo-upload formControlName="photo"></app-photo-upload>
					<div class="error-message" *ngIf="isRequiredError('photo.photo')">
						{{ "teacher-onboarding.required-error" | translate }}
					</div>
					<div class="flex justify-end gap-2 mt-8">
						<button mat-flat-button color="primary" class="s-mat-button s-mat-primary" (click)="previousSection()">
							{{ "buttons.back" | translate }}
						</button>
						<button class="s-mat-button" mat-flat-button color="accent" (click)="savePhoto()">
							{{ "buttons.save-continue" | translate }}
						</button>
					</div>
				</ng-container>

				<!-- musical details -->

				<ng-container *ngSwitchCase="2" formGroupName="musicalDetails">
					<div class="font-bold text-lg mb-2">
						{{ "teacher-onboarding.music.title" | translate }}
					</div>
					<div class="mb-6">
						{{ "teacher-onboarding.music.subtitle" | translate }}
					</div>

					<div formArrayName="instruments">
						<section class="mb-8" [formGroup]="iControl" *ngFor="let iControl of instrumentsFormArray.controls; let index = index">
							<div class="flex">
								<div class="font-bold mb-px">
									{{ "teacher-onboarding.instrument.title" | translate }}
								</div>
							</div>
							<mat-form-field>
								<mat-select formControlName="instrument" [placeholder]="'teacher-onboarding.instrument.placeholder' | translate">
									<mat-option *ngFor="let i of instruments" [value]="i.value">
										{{ i.title }}
									</mat-option>
								</mat-select>
								<mat-error *ngIf="instrumentError(index, 'instrument')">
									{{ "teacher-onboarding.required-error" | translate }}
								</mat-error>
							</mat-form-field>
							<div class="font-bold mb-px">
								{{ "teacher-onboarding.genres.title" | translate }}
							</div>
							<mat-form-field>
								<mat-select multiple formControlName="genre" [placeholder]="'teacher-onboarding.genres.placeholder' | translate">
									<mat-option *ngFor="let g of genres" [value]="g.value">
										{{ g.title }}
									</mat-option>
								</mat-select>
								<mat-error *ngIf="instrumentError(index, 'genre')">
									{{ "teacher-onboarding.required-error" | translate }}
								</mat-error>
							</mat-form-field>
							<div class="font-bold mb-px">
								{{ "teacher-onboarding.higherEducation.title" | translate }}
							</div>
							<mat-form-field>
								<input matInput formControlName="higherEducation" [placeholder]="'teacher-onboarding.higherEducation.placeholder' | translate" type="text">
							</mat-form-field>
							<div class="font-bold mb-px">
								{{ "teacher-onboarding.degree.title" | translate }}
							</div>
							<mat-form-field>
								<input matInput formControlName="degree" [placeholder]="'teacher-onboarding.degree.placeholder' | translate" type="text">
							</mat-form-field>
							<div class="font-bold mb-px">
								{{ "teacher-onboarding.degreeDescription.title" | translate }}
							</div>
							<mat-form-field>
								<textarea rows="4" matInput formControlName="degreeDescription" [placeholder]="'teacher-onboarding.degreeDescription.placeholder' | translate" type="text"></textarea>
							</mat-form-field>
							<!-- <div class="bg-cold-offwhite p-4">
								<div class="font-bold mb-1">
									{{ "teacher-onboarding.certification.title" | translate }}
								</div>
								<div class="mb-2">
									{{ "teacher-onboarding.certification.subtitle" | translate }}
								</div>
								<div class="mb-2">
									{{ "teacher-onboarding.certification.formats" | translate }}
								</div>
								<input type="file" class="hidden" (change)="onFileSelected($event, index)" #fileUpload>
								<button class="s-mat-button" mat-flat-button color="accent" (click)="fileUpload.click()">
									{{ "teacher-onboarding.certification.upload" | translate }}
								</button>
							</div> -->
							@if(index !== 0){
							<div>
								<button class="add-instrument" (click)="deleteInstrument(index)">
									{{'buttons.remove-instrument' | translate}}
									<div class="ml-1">
										<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor">
											<path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z" />
										</svg>
									</div>
								</button>
							</div>
							}
						</section>
					</div>
					<button class="add-instrument" (click)="addInstrument()">{{'buttons.add-instrument' | translate}}</button>
					<div class="flex justify-end gap-2 mt-8">
						<button mat-flat-button color="primary" class="s-mat-button s-mat-primary" (click)="previousSection()">
							{{ "buttons.back" | translate }}
						</button>
						<button class="s-mat-button" mat-flat-button color="accent" (click)="saveGroup(musicalDetails)">
							{{ "buttons.save-continue" | translate }}
						</button>
					</div>
				</ng-container>

				<!-- profileDescription -->

				<ng-container *ngSwitchCase="3" formGroupName="profileDescription">
					<div class="font-bold text-lg mb-2">
						{{ "teacher-onboarding.profileDescription.title" | translate }}
					</div>
					<div class="mb-6">
						{{ "teacher-onboarding.profileDescription.subtitle" | translate }}
					</div>
					<section class="mb-2">
						<div class="font-bold mb-1">
							{{ "teacher-onboarding.introduce-yourself.title" | translate }}
						</div>
						<div class="mb-2">
							{{ "teacher-onboarding.introduce-yourself.subtitle" | translate }}
						</div>
						<mat-form-field class="w-96">
							<textarea rows="4" formControlName="teacherIntroduction" matInput [placeholder]="'teacher-onboarding.introduce-yourself.placeholder' | translate"></textarea>
							<mat-error *ngIf="isRequiredError('profileDescription.teacherIntroduction')">
								{{ "teacher-onboarding.required-error" | translate }}
							</mat-error>
						</mat-form-field>
					</section>
					<section class="mb-2">
						<div class="font-bold mb-1">
							{{ "teacher-onboarding.teaching-experience.title" | translate }}
						</div>
						<div class="mb-2">
							{{ "teacher-onboarding.teaching-experience.subtitle" | translate }}
						</div>
						<mat-form-field class="w-96">
							<textarea rows="4" formControlName="teachingExperience" matInput [placeholder]="'teacher-onboarding.teaching-experience.placeholder' | translate"></textarea>
							<mat-error *ngIf="isRequiredError('profileDescription.teachingExperience')">
								{{ "teacher-onboarding.required-error" | translate }}
							</mat-error>
						</mat-form-field>
					</section>
					<section class="mb-2">
						<div class="font-bold mb-1">
							{{ "teacher-onboarding.motivate-students.title" | translate }}
						</div>
						<div class="mb-2">
							{{ "teacher-onboarding.motivate-students.subtitle" | translate }}
						</div>
						<mat-form-field class="w-96">
							<textarea rows="4" formControlName="motivateStudents" matInput [placeholder]="'teacher-onboarding.motivate-students.placeholder' | translate"></textarea>
							<mat-error *ngIf="isRequiredError('profileDescription.motivateStudents')">
								{{ "teacher-onboarding.required-error" | translate }}
							</mat-error>
						</mat-form-field>
					</section>

					<div class="flex justify-end gap-2 mt-8">
						<button mat-flat-button color="primary" class="s-mat-button s-mat-primary" (click)="previousSection()">
							{{ "buttons.back" | translate }}
						</button>
						<button class="s-mat-button" mat-flat-button color="accent" (click)="saveGroup(profileDescription)">
							{{ "buttons.save-continue" | translate }}
						</button>
					</div>
				</ng-container>

				<!-- setup -->

				<ng-container *ngSwitchCase="4" formGroupName="setup">
					<div class="font-bold text-lg mb-2">
						{{ "teacher-onboarding.setup.title" | translate }}
					</div>
					<div class="mb-6">
						{{ "teacher-onboarding.setup.subtitle" | translate }}
					</div>
					<section>
						<div class="mb-px">
							<span class="font-bold">
								{{ "teacher-onboarding.device.title" | translate }}
							</span>
						</div>
						<mat-form-field>
							<input matInput formControlName="device" [placeholder]="'teacher-onboarding.device.placeholder' | translate" type="text" />
							<mat-error *ngIf="isRequiredError('setup.device')">
								{{ "teacher-onboarding.required-error" | translate }}
							</mat-error>
						</mat-form-field>
					</section>
					<section>
						<div class="mb-px">
							<span class="font-bold">
								{{ "teacher-onboarding.browser.title" | translate }}
							</span>
						</div>
						<mat-form-field>
							<input matInput formControlName="browser" [placeholder]="'teacher-onboarding.browser.placeholder' | translate" type="text" />
							<mat-error *ngIf="isRequiredError('setup.browser')">
								{{ "teacher-onboarding.required-error" | translate }}
							</mat-error>
						</mat-form-field>
					</section>
					<section>
						<div class="mb-px">
							<span class="font-bold">
								{{ "teacher-onboarding.uploadMbps.title" | translate }}
							</span>
							<a class="underline text-offblack" target="_blank" href="https://app.sirius.video/setup">({{ "speed-test.perform-speedtest" | translate }})</a>
						</div>
						<mat-form-field>
							<input class="number-right-align" type="number" matInput formControlName="uploadMbps" />
							<span matTextSuffix>&nbsp;Mbps </span>
						</mat-form-field>
					</section>
					<section class="mb-2">
						<mat-checkbox formControlName="externalMic">{{ "teacher-onboarding.externalMic.title" | translate }}</mat-checkbox>
					</section>
					<section class="mb-2">
						<mat-checkbox formControlName="externalCamera">{{
							"teacher-onboarding.externalCamera.title" | translate
							}}</mat-checkbox>
					</section>
					<section class="mb-2">
						<mat-checkbox formControlName="secondCamera">{{
							"teacher-onboarding.secondCamera.title" | translate
							}}</mat-checkbox>
					</section>
					<div class="flex justify-end gap-2 mt-8">
						<button mat-flat-button color="primary" class="s-mat-button s-mat-primary" (click)="previousSection()">
							{{ "buttons.back" | translate }}
						</button>
						<button class="s-mat-button" mat-flat-button color="accent" (click)="saveGroup(setup)">
							{{ "buttons.save-continue" | translate }}
						</button>
					</div>
				</ng-container>

				<!-- students -->

				<ng-container *ngSwitchCase="5" formGroupName="students">
					<div class="font-bold text-lg mb-2">
						{{ "teacher-onboarding.students.title" | translate }}
					</div>
					<div class="mb-6">
						{{ "teacher-onboarding.students.subtitle" | translate }}
					</div>
					<section>
						<div class="mb-px">
							<span class="font-bold">
								{{ "teacher-onboarding.matchesNumberPreference.title" | translate }}
							</span>
						</div>
						<mat-form-field>
							<input matInput formControlName="matchesNumberPreference" type="number" />
							<mat-error *ngIf="isRequiredError('students.matchesNumberPreference')">
								{{ "teacher-onboarding.required-error" | translate }}
							</mat-error>
						</mat-form-field>
					</section>

					<section>
						<div class="mb-px">
							<span class="font-bold">
								{{ "teacher-onboarding.gendersPreference.title" | translate }}
							</span>
						</div>
						<mat-form-field>
							<mat-select multiple formControlName="gendersPreference" [placeholder]="'teacher-onboarding.gendersPreference.placeholder' | translate">
								<mat-option [value]="'noPreference'">
									{{ "teacher-onboarding.no-preference" | translate }}
								</mat-option>
								<mat-option *ngFor="let g of studentgenderList" [value]="g.value">
									{{ g.title | translate }}
								</mat-option>
							</mat-select>
							<mat-error *ngIf="isRequiredError('students.gendersPreference')">
								{{ "teacher-onboarding.required-error" | translate }}
							</mat-error>
						</mat-form-field>
					</section>

					<section>
						<div class="mb-px">
							<span class="font-bold">
								{{ "teacher-onboarding.agesPreference.title" | translate }}
							</span>
						</div>
						<mat-form-field>
							<mat-select multiple formControlName="agesPreference" [placeholder]="'teacher-onboarding.agesPreference.placeholder' | translate">
								<mat-option [value]="'noPreference'">
									{{ "teacher-onboarding.no-preference" | translate }}
								</mat-option>
								<mat-option *ngFor="let a of ageGroupsList" [value]="a"> {{ a }} </mat-option>
							</mat-select>
							<mat-error *ngIf="isRequiredError('students.agesPreference')">
								{{ "teacher-onboarding.required-error" | translate }}
							</mat-error>
						</mat-form-field>
					</section>
					<section>
						<div class="mb-px">
							<span class="font-bold">
								{{ "teacher-onboarding.skillLevelPreference.title" | translate }}
							</span>
						</div>
						<mat-form-field>
							<mat-select multiple formControlName="skillLevelPreference" [placeholder]="'teacher-onboarding.skillLevelPreference.placeholder' | translate">
								<mat-option [value]="'noPreference'">
									{{ "teacher-onboarding.no-preference" | translate }}
								</mat-option>
								<mat-option *ngFor="let s of skillLevelsList" [value]="s.value">
									{{ s.title | translate }}
								</mat-option>
							</mat-select>
							<mat-error *ngIf="isRequiredError('students.skillLevelPreference')">
								{{ "teacher-onboarding.required-error" | translate }}
							</mat-error>
						</mat-form-field>
					</section>

					<section>
						<div class="font-bold mb-px">
							{{ "teacher-onboarding.preferenceComment.title" | translate }}
						</div>
						<mat-form-field class="w-96">
							<textarea rows="4" formControlName="preferenceComment" matInput></textarea>
						</mat-form-field>
					</section>

					<div class="flex justify-end gap-2 mt-8">
						<button mat-flat-button color="primary" class="s-mat-button s-mat-primary" (click)="previousSection()">
							{{ "buttons.back" | translate }}
						</button>
						<button class="s-mat-button" mat-flat-button color="accent" (click)="saveGroup(students)">
							{{ "buttons.save-continue" | translate }}
						</button>
					</div>
				</ng-container>

				<!-- payout details -->

				<ng-container *ngSwitchCase="6" formGroupName="payoutDetails">
					<div class="font-bold text-lg mb-2">
						{{ "teacher-onboarding.payout.title" | translate }}
					</div>
					<div class="mb-6">
						{{ "teacher-onboarding.payout.subtitle" | translate }}
					</div>
					<section class="mb-2">
						<div class="font-bold mb-px">
							{{ "teacher-onboarding.tax-number.title" | translate }}
						</div>
						<mat-form-field>
							<input matInput formControlName="taxNumber" [placeholder]="'teacher-onboarding.tax-number.placeholder' | translate" type="text" />
							<mat-error *ngIf="isRequiredError('payoutDetails.taxNumber')">
								{{ "teacher-onboarding.required-error" | translate }}
							</mat-error>
						</mat-form-field>
					</section>
					<section class="mb-2">
						<div class="font-bold mb-px">
							{{ "teacher-onboarding.tax-residency.title" | translate }}
						</div>
						<mat-form-field>
							<input matInput formControlName="taxAddress" type="text" [placeholder]="'teacher-onboarding.tax-residency.placeholder' | translate" />
							<mat-error *ngIf="isRequiredError('payoutDetails.taxAddress')">
								{{ "teacher-onboarding.required-error" | translate }}
							</mat-error>
						</mat-form-field>
					</section>
					<section class="mb-2">
						<div class="font-bold mb-px">
							{{ "teacher-onboarding.vat-status.title" | translate }}
						</div>
						<mat-form-field>
							<mat-select formControlName="vatStatus" panelClass="s-mat-checkbox app-select-panel s-mat-select-panel border" [placeholder]="'teacher-onboarding.vat-status.placeholder' | translate">
								<mat-option *ngFor="let v of vatStatus" [value]="v.value">
									{{ v.title | translate }}
								</mat-option>
							</mat-select>
							<mat-error *ngIf="isRequiredError('payoutDetails.vatStatus')">
								{{ "teacher-onboarding.required-error" | translate }}
							</mat-error>
						</mat-form-field>
					</section>
					<section class="mb-2">
						<div class="font-bold mb-px">
							{{ "teacher-onboarding.bank-account.title" | translate }}
						</div>
						<div class="mb-2">
							<mat-form-field>
								<input matInput formControlName="accountName" [placeholder]="'teacher-onboarding.bank-account.placeholder1' | translate" type="text" />
								<mat-error *ngIf="isRequiredError('payoutDetails.accountName')">
									{{ "teacher-onboarding.required-error" | translate }}
								</mat-error>
							</mat-form-field>
						</div>

						<div class="mb-2">
							<div class="font-bold mb-px">IBAN</div>
							<mat-form-field>
								<input matInput formControlName="iban" placeholder="IBAN" type="text" />
								<mat-error *ngIf="isRequiredError('payoutDetails.iban')">
									{{ "teacher-onboarding.required-error" | translate }}
								</mat-error>
							</mat-form-field>
						</div>
						<div class="mb-2">
							<div class="font-bold mb-px">BIC</div>
							<mat-form-field>
								<input matInput formControlName="bic" placeholder="BIC" type="text" />
								<mat-error *ngIf="isRequiredError('payoutDetails.bic')">
									{{ "teacher-onboarding.required-error" | translate }}
								</mat-error>
							</mat-form-field>
						</div>
					</section>
					<div class="flex justify-end gap-2 mt-8">
						<button mat-flat-button color="primary" class="s-mat-button s-mat-primary" (click)="previousSection()">
							{{ "buttons.back" | translate }}
						</button>
						<button class="s-mat-button" mat-flat-button color="accent" type="submit" (click)="saveGroup(payoutDetails)">
							{{ "buttons.save-continue" | translate }}
						</button>
					</div>
				</ng-container>

				<!-- terms -->

				<ng-container *ngSwitchCase="7" [formGroupName]="'terms'">
					<div class="font-bold text-lg mb-4">
						{{ "teacher-onboarding.terms.title" | translate }}
					</div>
					<div class="mb-8">
						<div class="mb-2">
							{{ "teacher-onboarding.terms-list.title" | translate }}
						</div>
						<ul class="terms-list">
							<li>
								<span class="material-symbols-outlined align-middle">check</span>
								<div class="align-middle ml-2">
									{{ "teacher-onboarding.terms-list.item-1" | translate }}
								</div>
							</li>
							<li>
								<span class="material-symbols-outlined align-middle">check</span>
								<div class="align-middle ml-2">
									{{ "teacher-onboarding.terms-list.item-2" | translate }}
								</div>
							</li>
							<li>
								<span class="material-symbols-outlined align-middle">check</span>
								<div class="align-middle ml-2">
									{{ "teacher-onboarding.terms-list.item-3" | translate }}
								</div>
							</li>
							<li>
								<span class="material-symbols-outlined align-middle">check</span>
								<div class="align-middle ml-2">
									{{ "teacher-onboarding.terms-list.item-4" | translate }}
								</div>
							</li>
							<li>
								<span class="material-symbols-outlined align-middle">check</span>
								<div class="align-middle ml-2">
									{{ "teacher-onboarding.terms-list.item-5" | translate }}
								</div>
							</li>
						</ul>
					</div>
					<div class="renumeration-list mb-8">
						<div class="mb-2">
							{{ "teacher-onboarding.renumeration-list.title" | translate }}
						</div>
						<ul>
							<li class="flex justify-between w-full">
								<div>
									{{ "teacher-onboarding.renumeration-list.item-1" | translate }}
								</div>
								<div class="font-bold">10€</div>
							</li>
							<li class="flex justify-between w-full">
								<div>
									{{ "teacher-onboarding.renumeration-list.item-2" | translate }}
								</div>
								<div class="font-bold">10€</div>
							</li>
							<li class="flex justify-between w-full">
								<div>
									{{ "teacher-onboarding.renumeration-list.item-3" | translate }}
								</div>
								<div class="font-bold">24€</div>
							</li>
							<li class="flex justify-between w-full">
								<div>
									{{ "teacher-onboarding.renumeration-list.item-4" | translate }}
								</div>
								<div class="font-bold">10€</div>
							</li>
							<li class="flex justify-between w-full">
								<div>
									{{ "teacher-onboarding.renumeration-list.item-5" | translate }}
								</div>
								<div class="font-bold">200€</div>
							</li>
						</ul>
					</div>
					<div class="terms-info mb-8">
						<span class="material-symbols-outlined absolute -ml-[28px] -mt-[12px] text-[20px] leading-[20px]">info</span>
						<div>
							{{ "teacher-onboarding.terms-info" | translate }}
						</div>
					</div>
					<div class="mb-2">
						<div class="flex items-center">
							<mat-checkbox formControlName="criminalHistoryConfirmation"></mat-checkbox><span>
								{{ "teacher-onboarding.criminalHistoryConfirmation" | translate }}
							</span>
						</div>
						<div class="error-message" *ngIf="isRequiredError('terms.criminalHistoryConfirmation')">
							{{ "teacher-onboarding.required-error" | translate }}
						</div>
					</div>

					<div class="mb-2">
						<div class="flex items-center">
							<mat-checkbox formControlName="termsConfirmation"></mat-checkbox><span class="[&>a]:text-offblack [&>a]:font-bold [&>a]:underline" [innerHTML]="'teacher-onboarding.termsConfirmation' | translate | safeHtml"></span>
						</div>
						<div class="error-message" *ngIf="isRequiredError('terms.termsConfirmation')">
							{{ "teacher-onboarding.required-error" | translate }}
						</div>
					</div>
					<div class="flex justify-end gap-2 mt-10">
						<button mat-flat-button color="primary" class="s-mat-button s-mat-primary" (click)="previousSection()">
							{{ "buttons.back" | translate }}
						</button>
						<button class="s-mat-button" mat-flat-button color="accent" (click)="finish()">
							{{ "buttons.send-application" | translate }}
						</button>
					</div>
				</ng-container>
			</form>
		</div>
	</div>
</div>