<div class="container flex justify-between w-full">
	<div class="flex flex-col grow">

		<img class="sirius-logo ml-[20px] mt-[20px]" src="/assets/icons/sirius/Sirius.png" alt="Sirius" />
		<div class="content mx-auto px-2" [class.my-auto]="isLoading || show404">
			<div>
				@if (show404){
				<div class="text-center max-w-md">
					<h2 class="text-headline-1 font-bold m-0">
						{{"404-page.title" | translate}}
					</h2>
					<p class="text-text-1">
						{{"404-page.subtitle" | translate}}
					</p>
					<p class="text-text-1 mt-4">
						{{"404-page.message-1" | translate}}
					</p>
					<p class="text-text-1 mt-4" [innerHTML]="'404-page.message-2' | translate"></p>
				</div>
				} @else if(showNoUpcomingLessonsMessage){
				<h2 class="text-text-1 mt-12">{{"trial-booking-page.no-upcoming-lessons-with" | translate: { teacherName } }}</h2>
				} @else if (!bookedLesson && !isLoading && !bookingExists) {
				@if(!isRescheduling){
				<h1 class="text-headline-1 mt-12 max-sm:font-bold max-sm:text-center"> {{"trial-booking-page.book-trial-with" | translate}} {{teacherName}} </h1>
				<h2 class="mb-5 text-text-1 max-sm:text-center">{{"trial-booking-page.select-timeslot" | translate}}</h2>
				}
				@else {
				<div class="text-text-1 mt-12 max-sm:text-center">Former time: <span class="line-through" *ngFor="let booking of existingBookings">{{ booking.startTime | date:'dd.MM.yy HH:mm' }}</span></div>
				<div class="mb-5 text-text-1 max-sm:text-center">{{ "trial-booking-page.select-timeslot-new" | translate: { teacherName } }}</div>
				}

				<!-- desktop calendar -->
				<div class="dates-container max-sm:hidden flex-row flex gap-1 xl:gap-4 ">
					<div class="column text-center" *ngFor="let availability of teacherAvailabilities">
						<span class="weekday block pt-3">{{ availability.day | translate }}</span>
						<span class="mb-3 block date">{{ availability.date | date: 'dd.MM.yy' }}</span>
						<div class="timeslot-container">
							<div [ngClass]="{ 'selected': selectedTimeslot === timeslot }" (click)="(selectTimeslot(timeslot))" class="timeslot" *ngFor="let timeslot of availability.timestamps">
								{{ timeslot | date: 'HH:mm' }}
							</div>
						</div>
					</div>
				</div>


				<!-- mobile calendar -->
				<div class="sm:hidden">
					<div class="flex bg-white rounded-[4px] justify-evenly mb-[24px] p-1">
						@for (availability of teacherAvailabilities; track availability; let idx = $index){
						<div class="flex flex-col grow text-center">

							<div class="weekday px-1 py-2 border-b border-cold-offwhite">{{ availability.day | translate | slice:0:2 }}</div>
							@if(!availability?.timestamps?.length){
							<button class="p-1 pt-2">
								<div class="transition-all border hover:pointer  rounded-[4px] p-1 text-offblack border-cold-offwhite bg-white">{{ availability.date | date: 'dd.MM' }}</div>
							</button>
							} @else {
							<button class="p-1 pt-2" (click)="selectDay(idx)">
								<div class="transition-all border hover:pointer  rounded-[4px] p-1" [ngClass]="selectedDay === idx ? 'bg-indigo text-white border-indigo' : 'text-offblack border-cold-offwhite bg-cold-offwhite'">
									{{ availability.date | date: 'dd.MM' }}
								</div>
							</button>
							}
						</div>
						}
					</div>

					<div class="dates-container flex-col flex gap-1 xl:gap-4">
						<div class="column text-center">
							<div class="timeslot-container" *ngIf="teacherAvailabilities?.length">
								<div [ngClass]="{ 'selected': selectedTimeslot === timeslot }" (click)="(selectTimeslotMobile(timeslot))" class="timeslot" *ngFor="let timeslot of teacherAvailabilities[selectedDay]?.timestamps">
									{{ timeslot | date: 'HH:mm' }}
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="bg-white" class="button-container-bar max-sm:hidden">
					<button mat-flat-button [disabled]="buttonIsDisabled" (click)="handleConfirmClick()" color="primary">
						{{'trial-booking-page.confirm-booking' | translate}}
					</button>
				</div>

				}

				@else if (isLoading){
				<div class="spinner-container ">
					<mat-progress-spinner diameter="40" mode="indeterminate"></mat-progress-spinner>
				</div>
				}

				@else if (showRescheduleConfirmation){
				<p class="text-headline-1 mt-12 max-sm:text-center">{{"trial-booking-page.reschedule-confirmed" | translate}}</p>
				<p class="text-text-1 max-sm:text-center" *ngFor="let booking of existingBookings">

					{{ booking.startTime | date:'dd.MM.yy HH:mm' }}

				</p>
				}

				@else if (bookingExists) {
				<p class="text-headline-1 mt-12 max-sm:text-center">{{"trial-booking-page.booking-confirmed" | translate}}</p>
				<p class="text-text-1 mt-2 max-sm:text-center" *ngFor="let booking of existingBookings">

					{{ booking.startTime | date:'dd.MM.yy HH:mm' }}

				</p>
				<div class="max-sm:!text-center">
					<button class="mt-8" mat-flat-button color="primary" (click)="switchToRescheduling()">{{ 'trial-booking-page.reschedule-trial-lesson' | translate }}</button>
				</div>
				}

				@else {

				<h1 class="text-headline-1 mt-12 max-sm:text-center">{{"trial-booking-page.booking-confirmed" | translate}}</h1>
				<p class="text-text-1 mt-2 max-sm:text-center">{{bookedLessonDate | date:'dd.MM.yy HH:mm'}}</p>
				<div class="max-sm:!text-center">
					<button class="mt-8 max-sm:!text-center" mat-flat-button color="primary" (click)="switchToRescheduling()">{{ 'trial-booking-page.reschedule-trial-lesson' | translate }}</button>
				</div>
				}

			</div>

		</div>

	</div>
	<div class="image-container">
		<img src="../../assets/images/sirius-singing.png" alt="">
	</div>

</div>