import { Component } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { DailyRtcService } from "../lib-rtc/services/daily-rtc.service";
import { h } from "@fullcalendar/core/preact";
import { co } from "@fullcalendar/core/internal-common";

enum DeviceType {
	VIDEO = "videoDeviceId",
	AUDIO = "audioDeviceId"
}

@Component({
	selector: "app-daily-video-settings",
	standalone: true,
	imports: [ReactiveFormsModule, CommonModule],
	templateUrl: "./daily-video-settings.component.html",
	styleUrl: "./daily-video-settings.component.scss"
})
export class DailyVideoSettingsComponent {
	deviceForm: FormGroup;
	videoDevices: MediaDeviceInfo[] = [];
	audioDevices: MediaDeviceInfo[] = [];
	bandwidth: number = 0;

	constructor(
		private fb: FormBuilder,
		private dailyRtcService: DailyRtcService
	) {
		this.deviceForm = this.fb.group({
			videoInput: [""],
			audioInput: [""],
			enableSoundProcessor: [false],
			videoSettings:[""]
		});
	}

	ngOnInit() {
		this.getDevices();
		this.deviceForm.get("videoInput")?.valueChanges.subscribe((value) => {
			this.dailyRtcService.setInputDevice(value, DeviceType.VIDEO);
		});
		this.deviceForm.get("audioInput")?.valueChanges.subscribe((value) => {
			this.dailyRtcService.setInputDevice(value, DeviceType.AUDIO);
		});
		this.deviceForm
			.get("videoSettings")
			?.valueChanges.subscribe((value) => {
				const [bandwidth, framerate] = value.split('_').map(Number);
				let width: number, height: number;
			
    // Set resolution based on bandwidth (maintaining 4:3 ratio)
    switch (bandwidth) {
		case 400:  // 360p - 4:3
		  width = 480;   // Calculated width for 360p at 4:3 aspect ratio
		  height = 360;  // Height remains 360p for 4:3 aspect ratio
		  break;
		case 800:  // 480p - 4:3
		  width = 640;   // Calculated width for 480p at 4:3 aspect ratio
		  height = 480;  // Height remains 480p for 4:3 aspect ratio
		  break;
		case 1500: // 720p - 4:3
		  width = 960;   // Calculated width for 720p at 4:3 aspect ratio
		  height = 720;  // Height remains 720p for 4:3 aspect ratio
		  break;
		case 3000: // 1080p - 4:3
		  width = 1440;  // Calculated width for 1080p at 4:3 aspect ratio
		  height = 1080; // Height remains 1080p for 4:3 aspect ratio
		  break;
		case 5000: // 1440p - 4:3
		  width = 1920;  // Calculated width for 1440p at 4:3 aspect ratio
		  height = 1440; // Height remains 1440p for 4:3 aspect ratio
		  break;
		default:
		  width = 480;   // Default to 360p at 4:3 aspect ratio
		  height = 360;
	  }

				console.log(width, height, framerate);

				this.dailyRtcService.setBandwidth(width,height,framerate);
			});
		this.deviceForm
			.get("enableSoundProcessor")
			.valueChanges.subscribe((value) => {
				this.dailyRtcService.updateInputSettings(value);
			});

	}

	async getDevices() {
		const devices = await navigator.mediaDevices.enumerateDevices();
		this.videoDevices = devices.filter(
			(device) => device.kind === "videoinput"
		);
		this.audioDevices = devices.filter(
			(device) => device.kind === "audioinput"
		);
	}
}
