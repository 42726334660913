import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild
} from "@angular/core";
import {
	LessonsService
} from "../services/lessons/lessons.service";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { flatMap } from "rxjs/internal/operators";
import { Profile } from "../types/profile.types";
import { RecordingListItem } from "../services/recording/recording.service";
import { ProfileService } from "../profile/profile.service";
import { ButtonType } from "../components/call-room/data/ButtonType";
import { PopupService } from "../services/popup/popup.service";
import { Subject } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { takeUntil } from "rxjs/operators";
// import { HelpScoutService } from "../services/help-scout-service.service";
import { isInPast } from "../helpers/time";
import { convertToTime, convertToDate } from "../utils/convert-time.util";
import { IntercomService } from "../services/intercom/intercom.service";
import { BookingType } from "@data-types/booking.type";

@Component({
	selector: "app-lessons",
	templateUrl: "./lessons.component.html",
	styleUrls: ["./lessons.component.scss"]
})
export class LessonsComponent implements OnInit, OnDestroy {
	@Output() onStartSession = new EventEmitter();
	@Output() onOpenMoreLessonInfoModal = new EventEmitter();
	@Input() availableStudents;
	@Input() selectedTeacher;

	displayedColumns: string[] = [
		"Data"
		//  "Data-time"
	];
	dataSource = new MatTableDataSource<any>();
	lessonsList = [];
	private profileId: string;
	size: number;
	currentSize: number = 4;
	page: string = "1";
	limit: string;
	destroy$ = new Subject();
	subrole: number;
	timeZone: string;
	displayPastLessons: boolean = false;
	isBetaTester: boolean = false;

	@ViewChild(MatPaginator) paginator: any;
	constructor(
		private lessonService: LessonsService,
		private profile: ProfileService,
		private popupService: PopupService,
		private translateService: TranslateService,
		private intercom: IntercomService
		// private helpScoutService: HelpScoutService
	) {}

	async ngOnInit() {
		this.intercom.hideIntercom(false);
		this.initLessonsList();
		this.lessonService.updateBookingList$
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				this.handleBookingChange();
			});
		this.translateService.onLangChange
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				this.handleBookingChange();
			});
	}
	ngOnChanges(changes: SimpleChanges) {
		if (
			changes["selectedTeacher"] &&
			!changes["selectedTeacher"].isFirstChange()
		) {
			this.handleSelectedTeacher(changes["selectedTeacher"].currentValue);
		}
	}

	handleSelectedTeacher(teacher) {
		this.initLessonsList(teacher);
	}

	ngOnDestroy() {
		this.destroy$.next();
		// this.helpScoutService.destroyBeacon();
	}

	initLessonsList(teacher?) {
		this.profile
			.get()
			.pipe(
				flatMap((profile: Profile) => {
					this.isBetaTester = profile.betaTesting;
					this.profileId = profile.userId;
					this.subrole = profile.subrole;
					this.timeZone = profile.timezone;
					return this.lessonService.getBookings(
						this.profileId,
						"1",
						"50",
						this.displayPastLessons
					);
				})
			)
			.subscribe((responseList) => {
				let { count, list } = responseList; // bookings list
				this.lessonsList = list.map((listItem: any) => {
					return {
						...listItem,
						inPast: isInPast(listItem.endTime, this.timeZone),
						type: listItem.type.toUpperCase(),
						time: convertToTime(listItem.startTime, this.timeZone),
						date: convertToDate(listItem.startTime, this.timeZone),
						day: this.convertToRelativeDate(listItem.startTime),
						subRoleName: this.getSubRoleName(listItem),
						canBeCancel: this.checkCanBeCancel(listItem.startTime)
					};
				});
				// .filter((lesson) => {
				// 	return this.displayPastLessons ? true : !lesson.inPast;
				// });
				const filteredList = this.lessonsList.filter((item) => {
					// if (teacher.name) {
					// 	return (
					// 		item.type !== "TRIAL" &&
					// 		item.subRoleName === teacher.name
					// 	);
					// } else {
					// 	return item.type !== "TRIAL";
					// }
					return teacher?.name
						? item.type !== "TRIAL" &&
								item.subRoleName === teacher.name
						: item.type !== "TRIAL";
				});
				this.dataSource = new MatTableDataSource<RecordingListItem>(
					this.subrole == 6 ? filteredList : this.lessonsList
				);
				this.size = teacher === "all" ? count : filteredList.length;
			});
	}

	handleBookingChange() {
		this.initLessonsList();
		// const event: PageEvent = {
		// 	length: this.paginator.length,
		// 	pageIndex: this.paginator.pageIndex,
		// 	pageSize: this.paginator.pageSize
		// };
		// this.paginator.page.next(event);
	}

	getNextData(currentSize: number, page: string, limit: string) {
		this.currentSize = currentSize;
		this.page = page;
		this.limit = limit;
		this.lessonService
			.getBookings(this.profileId, page, limit, this.displayPastLessons)
			.subscribe(({ list, count }) => {
				this.size = count;
				if (count > 4) {
					this.lessonsList.length = currentSize;
					this.lessonsList.push(...list);
				} else {
					this.lessonsList = list;
				}
				this.lessonsList = this.lessonsList.map((listItem: any) => {
					return {
						...listItem,
						inPast: isInPast(listItem.endTime, this.timeZone),
						type: listItem.type.toUpperCase(),
						time: convertToTime(listItem.startTime, this.timeZone),
						date: convertToDate(listItem.startTime, this.timeZone),
						day: this.convertToRelativeDate(listItem.startTime),
						subRoleName: this.getSubRoleName(listItem),
						canBeCancel: this.checkCanBeCancel(listItem.startTime)
					};
				});
				const days = this.lessonsList.map((lesson) => lesson.day);
				this.lessonsList.forEach((lesson, index) => {
					if (index % 4 !== 0 && days.indexOf(lesson.day) != index) {
						lesson.day = "";
					}
				});
				if (count > 4) {
					this.lessonsList.length = count;
				}
				this.dataSource = new MatTableDataSource<any>(this.lessonsList);
				this.dataSource._updateChangeSubscription();
				this.dataSource.paginator = this.paginator;
			});
	}

	pageChanged(event) {
		let pageIndex = event.pageIndex + 1;
		let pageSize = event.pageSize;
		let calcSize = event.pageIndex || 1;
		let previousSize = pageSize * calcSize;
		this.getNextData(
			previousSize,
			pageIndex.toString(),
			pageSize.toString()
		);
	}

	convertToRelativeDate(time: string): string {
		const selectedLanguage = this.translateService.currentLang;
		const dateLocal = selectedLanguage === "eng" ? "en-US" : "de-DE";
		const options: Intl.DateTimeFormatOptions = {
			year: "numeric",
			month: "long",
			day: "2-digit"
		};
		const date = new Date(time);
		const fullDate = date.toLocaleDateString(dateLocal, options);
		if (this.isToday(date)) {
			return this.translateService.instant("lessons.today");
		} else if (this.isTomorrow(date)) {
			return this.translateService.instant("lessons.tomorrow");
		} else {
			return fullDate;
		}
	}

	getSubRoleName(item): string {
		if (this.subrole === 3) {
			return item.student.name;
		}
		return item.teacher.name;
	}

	isTomorrow(date): boolean {
		const tomorrow = new Date();
		tomorrow.setDate(tomorrow.getDate() + 1);
		return tomorrow.toDateString() === date.toDateString();
	}

	isToday(date): boolean {
		const today = new Date();
		return (
			today.getFullYear() === date.getFullYear() &&
			today.getMonth() === date.getMonth() &&
			today.getDate() === date.getDate()
		);
	}

	// cancelLesson(booking) {
	// 	const name = this.getSubRoleName(booking);
	// 	const cancelTitle = this.translateService.instant(
	// 		"lessons.confirmation-title"
	// 	);
	// 	const confirmData = {
	// 		student: { id: booking.studentId, name },
	// 		date: booking.startTime,
	// 		duration: booking.duration,
	// 		target: booking.targetTeacher,
	// 		title: cancelTitle
	// 	};
	// 	this.popupService.openConfirmLessonDialog(confirmData, {}, [
	// 		{
	// 			type: ButtonType.CANCEL,
	// 			text: "lessons.do-not-cancel-lesson"
	// 		},
	// 		{
	// 			type: ButtonType.OK,
	// 			text: "lessons.cancel-lesson",
	// 			callBack: (dialog) => {
	// 				const bookData = {
	// 					status: "canceled",
	// 					rating: "",
	// 					feedback: ""
	// 				};
	// 				this.lessonService
	// 					.updateBooking(booking.id, bookData)
	// 					.subscribe(() => {
	// 						const modal = dialog.getDialogById(
	// 							"confirm-lesson-dialog"
	// 						);
	// 						modal.close();
	// 						this.lessonService.updateBookingList$.next();
	// 					});
	// 			}
	// 		}
	// 	]);
	// }

	startSession(booking) {
		this.onStartSession.emit(booking);
	}

	openMoreLessonInfoModal(booking) {
		this.onOpenMoreLessonInfoModal.emit(booking);
	}

	checkCanBeCancel(time: string): boolean {
		const now = new Date().getTime();
		const futureDate = new Date(time).getTime();
		const difference = Math.abs(futureDate - now) / 36e5;
		return difference > 24;
	}

	onPastLessonToggleChange({ value }) {
		this.displayPastLessons = value === "past";
		this.initLessonsList();
	}

	rescheduleLesson(booking) {
		this.lessonService.rescheduleLesson(booking, this.timeZone);
	}

	isStudentInfoAvailable(booking) {
		if (this.subrole !== 3) {
			return false;
		}
		const studentinfo = booking?.student?.studentProfile;
		if (!studentinfo) {
			return false;
		}
		// check if there is any info to display

		const hasInfo = Object.values(studentinfo).some((value) => {
			if (typeof value === "string") {
				return value.trim().length > 0;
			} else if (Array.isArray(value)) {
				return value.length > 0;
			}
			return Boolean(value);
		});
		return hasInfo;
	}
	acceptRequest(bookingId) {
		this.popupService.openUpdateBookingDialog(bookingId, "accepted");
	}
	declineRequest(bookingId) {
		this.popupService.openUpdateBookingDialog(bookingId, "declined");
	}

	openEditRepeatBookingDialog(
		teacherId,
		studentId,
		studentName,
		lessonStartTime
	) {
		this.popupService.openEditRepeatBookingDialog(
			teacherId,
			studentId,
			studentName,
			lessonStartTime
		);
	}

	cancelBooking(bookingId: string, bookingType: BookingType) {
		this.popupService.openCancelBookingDialog(bookingId, bookingType);
	}
}
