import {
  Component, OnInit, ViewEncapsulation, ViewChild,
  ElementRef, Output, EventEmitter, Input, OnChanges
} from '@angular/core';

import { NoteProperties} from '../utils/tuner-media';
import { createTuner } from '@chordbook/tuner'
export interface NoteData {
  name: string;
  value: number;
  octave: string;
  frequency: number;
  shift: number;
  clientWidth: number;
}

const A4_DEFAULT = 442;

@Component({
  selector: 'app-tuner',
  templateUrl: './tuner.component.html',
  styleUrls: ['./tuner.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TunerComponent implements OnChanges, OnInit {
  @Input()
  public isMobile = false;
  @Input()
  public isPortrait = false;
  @Input()
  public isBrowserSafari = false;
  @Output()
  readonly clickClose: EventEmitter<void> = new EventEmitter();
  @Output()
  readonly changeIsPortrait: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('materPointer', { static: true })
  public meterPointerElement: ElementRef<HTMLDivElement> = null;
  public notesListElement: ElementRef<HTMLVideoElement> | null = null;
  public a4Value = A4_DEFAULT;
  // Meter
  public meterScaleList: number[] = this.initMeterScaleList();
  public meterPointerValue = 0;
  public middleA = 440;
  public semitone = 69;
  public noteName:string = "A";
  public octave:number = 4;
  public tuner;

  constructor() {}

  ngOnChanges(): void {
      this.changeIsPortrait.emit(this.isPortrait);
  }

  ngOnInit(): void {



   this.tuner = createTuner({
        // The callback to call when a note is detected.
        onNote: note => {
          this.noteName = note.name;
          this.octave = note.octave;
          this.update(
            {
              name: note.name,
              frequency: note.frequency,
              octave: note.octave,
              value: note.value,
              cents: note.cents
            }
          );

        },
      
        // Here are some other settings you can fiddle with and their default values.
        // (let us know if you find values that work better).
      
        // The number of milliseconds between each pitch detection.
        updateInterval: 50,
      
        // The frequency of middle A. Defaults to 440Hz.
        a4: 440,
      
        // The minimum clarity threshold. Anything below this will be ignored
        clarityThreshold: 0.9,
      
        // The minimum volume threshold. -1000 means 1/1000th the volume of the loudest sound.
        minVolumeDecibels: -1000,
      
        // The minimum and maximum frequencies to detect. To reduce noise, everything else is
        // filtered out using a lowpass and highpass filter.
        minFrequency: 27.5, // A0, Lowest note on a piano
        maxFrequency: 4186.01, // C8, Highest note on a piano
      
        // The sample rate to use for the audio context.
        // https://developer.mozilla.org/en-US/docs/Web/API/BaseAudioContext/sampleRate
        sampleRate: 44100,
      
        // The size of buffer to use for frequency analysis, which maps to the `fftSize`:
        // https://developer.mozilla.org/en-US/docs/Web/API/AnalyserNode/fftSize
        bufferSize: 8192,
      
        // https://developer.mozilla.org/en-US/docs/Web/API/AnalyserNode/smoothingTimeConstant
        smoothingTimeConstant: 0.8
      })

        // Request access to the microphone and begin pitch detection
        this.tuner.start()
  }

  ngOnDestroy(): void {
    this.tuner.stop()
}


  public close(): void {
    this.clickClose.emit();
  }

  public isMeterScaleStrong(meterScale: number): boolean {
    return (meterScale % 5 === 0);
  }

  public trackByMeterScale(index: number, meterScale: number): number {
    return meterScale;
  }

  private initMeterScaleList(): number[] {
    const result: number[] = [];
    const valueDelta = 9;
    let valueStart = -45 - valueDelta;
    const valueFinish = 45;
    while (valueStart < valueFinish) {
      result.push(valueStart = valueStart + valueDelta);
    }
    return result;
  }

  private update(noteProperties: NoteProperties): void {
      this.updateMeterPointer(noteProperties.cents);
}

  private updateMeterPointer(cents: number): void {
    if (cents != null) {
      this.meterPointerValue = ((cents / 50) * 45);
      this.meterPointerElement.nativeElement.setAttribute('style', `transform: rotate(${this.meterPointerValue}deg)`);
    }
  }

}
