import { Component } from "@angular/core";
import { PlatformDetectorService } from "../services/platform-detector/platform-detector.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-setup",
  templateUrl: "./setup.component.html",
  styleUrls: ["./setup.component.scss"],
})
export class SetupComponent {
  currentStep = 0;
  completedSteps = 0;
  setupSteps = ["browser", "speed", "audio", "video"];
  completed = false;
  deviceInfo;

  constructor(
    private platformService: PlatformDetectorService,
    private router: Router
  ) {
    this.deviceInfo = this.platformService.deviceInfo || null;
  }

  isCurrentStep(step: string): boolean {
    return step === this.setupSteps[this.currentStep];
  }
  nextStep(): void {
    this.currentStep++;
  }
  onCompleteStep(step: number): void {
    if (step === this.completedSteps) {
      this.completedSteps++;
    }
    if (this.completedSteps === this.setupSteps.length) {
      this.completed = true;
    } else {
      this.nextStep();
    }
  }
  previousStep(): void {
    this.currentStep--;
  }

  get stepWidth(): string {
    return 100 / this.setupSteps.length + "%";
  }

  moveToStep(step: number): void {
    if (this.isClickable(step)) {
      this.currentStep = step;
    }
  }

  isClickable(step: number): boolean {
    return this.completedSteps >= step;
  }

  onSetupComplete(): void {
    this.router.navigate(["/"]);
  }

  get isConnectionGood(): boolean {
    const latestCheck = sessionStorage.getItem("latestCheck");
    if (latestCheck) {
      const latestResult = JSON.parse(latestCheck);
      return latestResult.speed > 20;
    }
    return true;
  }

  get isChrome(): boolean {
    return this.deviceInfo.browser === "Chrome";
  }
  get isDesktop(): boolean {
    return this.deviceInfo.deviceType === "desktop";
  }
  get isSetupGood(): boolean {
    return this.isConnectionGood && this.isChrome && this.isDesktop;
  }
}
