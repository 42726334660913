import { inject } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

type GroupedTimestamp = {
	day: string;
	date: string;
	timestamps: string[];
};

export function transformDateArray(
	dateArray: string[],
	timeZone: string
): GroupedTimestamp[] {
	// Get today's date and day index
	const today = new Date().toLocaleString("en-US", {
		timeZone
	});
	const todayDate = new Date(today);
	const todayDayIndex = todayDate.getDay();

	const daysOfWeek = [
		"availability.sunday",
		"availability.monday",
		"availability.tuesday",
		"availability.wednesday",
		"availability.thursday",
		"availability.friday",
		"availability.saturday"
	];

	const groupedTimestamps: GroupedTimestamp[] = [];
	const timestampsByDay: {
		[key: string]: { date: string; timestamps: string[] };
	} = {};

	// Initialize the timestampsByDay object for each day of the week
	for (const day of daysOfWeek) {
		timestampsByDay[day] = {
			date: "", // This will be set later
			timestamps: []
		};
	}

	// Process each timestamp
	dateArray.forEach((timestamp) => {
		const dateInBerlin = new Date(timestamp).toLocaleString("en-US", {
			timeZone
		});
		const date = new Date(dateInBerlin);
		const day = date.getDay();
		const dayName = daysOfWeek[day];

		// Format the date as 'YYYY-MM-DD'
		const formattedDate = date.toISOString().split("T")[0];

		// Set the date for the day if it's not already set (i.e., if it's the first timestamp for that day)
		if (!timestampsByDay[dayName].date) {
			timestampsByDay[dayName].date = formattedDate;
		}

		timestampsByDay[dayName].timestamps.push(timestamp);
	});

	// Build the groupedTimestamps array with dates
	for (let i = todayDayIndex; i < daysOfWeek.length; i++) {
		const dayName = daysOfWeek[i];
		const dateOffset = i - todayDayIndex; // Calculate the offset for date
		const futureDate = new Date(todayDate);
		futureDate.setDate(todayDate.getDate() + dateOffset); // Get the correct date
		const formattedDate = futureDate.toISOString().split("T")[0]; // Format as 'YYYY-MM-DD'
		groupedTimestamps.push({
			day: dayName,
			date: timestampsByDay[dayName].date || formattedDate, // Use existing date or calculated date
			timestamps: timestampsByDay[dayName].timestamps
		});
	}

	// Now handle the days from the beginning of the week to today
	for (let i = 0; i < todayDayIndex; i++) {
		const dayName = daysOfWeek[i];
		const dateOffset = i - todayDayIndex + 7; // Calculate the offset for past dates
		const pastDate = new Date(todayDate);
		pastDate.setDate(todayDate.getDate() + dateOffset); // Get the correct past date
		const formattedDate = pastDate.toISOString().split("T")[0]; // Format as 'YYYY-MM-DD'

		groupedTimestamps.push({
			day: dayName,
			date: timestampsByDay[dayName].date || formattedDate, // Use existing date or calculated date
			timestamps: timestampsByDay[dayName].timestamps
		});
	}

	// Sort the groupedTimestamps by date
	groupedTimestamps.sort((a, b) => {
		const dateA = new Date(a.date);
		const dateB = new Date(b.date);

		// Check if the date is valid
		if (isNaN(dateA.getTime()) || isNaN(dateB.getTime())) {
			console.error(
				"Eines der Datumswerte ist ungültig:",
				a.date,
				b.date
			);
			return 0; // Handle invalid dates here if needed
		}

		return dateA.getTime() - dateB.getTime(); // Use getTime() for comparison
	});

	return groupedTimestamps;
}
