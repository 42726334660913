import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ROOM, WebSocketService } from "../services/ws/web-socket.service";
import { getSubDomain } from "../helpers";
import { UsersService } from "../services/users.service";
import { UserVO } from "../services/data/UserVO";
import { ProfileService } from "../profile/profile.service";
import { PopupService } from "../services/popup/popup.service";

const TIMEOUT_MS = 90000;

@Component({
	selector: "app-call-header",
	templateUrl: "./call-header.component.html",
	styleUrls: ["./call-header.component.scss"]
})
export class CallHeaderComponent {
	@Input() isMobile: boolean;
	@Input() isFullscreen: boolean;
	@Output() exitFullscreen = new EventEmitter();
	@Input() isTeacher: boolean;
	@Input() theme;
	@Input() showTimer: boolean;
	@Input() subrole: number;
	@Input() studentId: string;
	@Input() betaTesting: boolean;
	@Output() toggleTimer = new EventEmitter();
	isSchool: boolean;
	private timerInterval;
	private seconds = 0;
	private timeout;
	public isTimingOut = false;
	public isTiming = false;
	public popup = false;
	startTime: number;
	userId: string;
	userTimezone: string;

	get time(): string {
		return this.formatTime(this.seconds);
	}

	get headerHeight(): number {
		return this.isMobile ? 24 : 32;
	}

	get logoPath(): string {
		return `../../assets/icons/sirius/sirius-${this.theme.iconColor}.svg`;
	}

	constructor(
		private webSocketService: WebSocketService,
		private usersService: UsersService,
		private profileService: ProfileService,
		private popupService: PopupService
	) {}

	ngOnInit(): void {
		this.initData();
		this.webSocketService.subscribe(ROOM, this.onSocketEvent);
		// uncomment for student side timer
		// if (this.isTeacher) {
		//   this.timerSocketEvent("start", this.seconds);
		// }
		this.profileService.get().subscribe((profile) => {
			this.userId = profile?.userId;
			this.userTimezone = profile?.timezone;
		});
	}

	onExitFullscreen(): void {
		this.exitFullscreen.emit();
	}

	private initData(): void {
		this.isSchool = !!getSubDomain();

		this.startTime = Number(sessionStorage.getItem("startTime"));

		if (
			sessionStorage.getItem("startTime") &&
			sessionStorage.getItem("timer") === "true" &&
			this.usersService.getConnectedUsers().length > 1 &&
			this.isTeacher
		) {
			this.seconds = this.getTimeElapsedSinceStart();

			this.startTimer();
		}
	}

	private getTimeElapsedSinceStart(): number {
		return Math.floor(
			(Date.now() - Number(sessionStorage.getItem("startTime"))) / 1000
		);
	}

	private onSocketEvent = (data): void => {
		const event = data[0]._event;
		if (event === "notify" && data[0]["timerEvent"]) {
			this.handleTimerEvent(data[0]["timerEvent"]);
		} else if (event === "join") {
			this.handleJoinEvent();
		} else if (event === "gone") {
			this.handleGoneEvent(data);
		}
	};

	private formatTime(seconds: number): string {
		const hours = Math.floor(seconds / 3600);
		const minutes = Math.floor((seconds % 3600) / 60);
		const secondsRemaining = seconds % 60;

		if (!hours && !minutes && !secondsRemaining) {
			return "00:00";
		} else if (hours >= 1) {
			return `${hours.toString().padStart(2, "0")}:${minutes
				.toString()
				.padStart(2, "0")}`;
		} else {
			return `${minutes.toString().padStart(2, "0")}:${secondsRemaining
				.toString()
				.padStart(2, "0")}`;
		}
	}

	private handleJoinEvent(): void {
		const connectedUsers = this.usersService.getConnectedUsers();
		if (this.isTeacher) {
			if (connectedUsers.length > 1) {
				this.isTimingOut = false;

				// uncomment for student side timer
				// setTimeout(() => {
				//   this.timerSocketEvent("start", this.seconds + 1);
				// }, 1000);

				this.startTimer();
			}
		}
		clearTimeout(this.timeout);
	}

	private handleGoneEvent(data): void {
		const connectedUsers: UserVO[] = this.usersService.getConnectedUsers();
		if (this.isTeacher) {
			if (connectedUsers.length === 1) {
				this.isTimingOut = true;
				this.timeout = setTimeout(() => {
					this.resetTimer();
					clearInterval(this.timerInterval);
					this.isTimingOut = false;
					this.isTiming = false;
					sessionStorage.removeItem("startTime");
					sessionStorage.removeItem("timer");
				}, TIMEOUT_MS);
			}
		}
	}

	// uncomment for student side timer
	// private timerSocketEvent(type: string, startTime: number): void {
	//   this.eventSocketService.notify(this.callStorage.roomId, {
	//     timerEvent: { type, startTime },
	//     userId: this.usersService.selfUser.id,
	//   });
	// }

	private handleTimerEvent(data): void {
		if (!this.isTeacher) {
			if (data.type === "start") {
				if (data.startTime) this.seconds = data.startTime;
				if (!this.isTiming) {
					this.startTimer();
				}
			} else if (data.type === "reset") {
				this.seconds = 0;
				if (!this.isTiming) {
					this.startTimer();
				}
			}
		}
	}

	startTimer(): void {
		this.isTiming = true;
		clearInterval(this.timerInterval);
		sessionStorage.setItem("timer", "true");
		if (!sessionStorage.getItem("startTime")) {
			sessionStorage.setItem("startTime", Date.now().toString());
		}
		this.timerInterval = setInterval(() => {
			this.seconds += 1;
		}, 1000);
	}

	resetTimer(): void {
		this.seconds = 0;
	}

	onClickResetTimer(): void {
		this.resetTimer();
		// uncomment for student side timer
		// this.timerSocketEvent("reset", this.seconds);
		sessionStorage.setItem("startTime", Date.now().toString());
	}

	ngOnDestroy(): void {
		this.webSocketService.unSubscribe(ROOM, this.onSocketEvent);
	}

	togglePopup(value: boolean): void {
		this.popup = value;
	}

	toggleTimerEvent(): void {
		this.toggleTimer.emit();
	}

	createRegularSession() {
		this.popupService.openCalendarEventDialog(
			this.userId,
			this.userTimezone,
			false,
			this.studentId
		);
	}
}
