import { Injectable } from "@angular/core";
import Daily, { DailyEvent } from "@daily-co/daily-js";
import { co } from "@fullcalendar/core/internal-common";
import { BehaviorSubject, Observable, Subject } from "rxjs";

enum ProcessorState {
	Enabled = "noise-cancellation",
	Disabled = "none"
}

@Injectable({
	providedIn: "root"
})
export class DailyRtcService {
	call;
	currentRoomUrl = null;
	public joinedMeeting$ = new Subject<any>();
	public participantJoined$ = new Subject<any>();
	public participantUpdated$ = new Subject<any>();
	public participantLeft$ = new Subject<any>();
	public mediaStreams = new Subject<any>();
	public isDailyRoom$ = new BehaviorSubject<any>(false);

	private events = new Subject<any>();

	constructor() {
		this.call = Daily.createCallObject({ dailyConfig: {
			micAudioMode: 'music',
		  },});
	}

	setEventListeners() {
		this.call.on("joined-meeting", (event: any) => {
			this.joinedMeeting$.next(event);
		});

		this.call.on("participant-joined", (event: any) => {
			this.participantJoined$.next(event);
		});

		this.call.on("participant-updated", (event: any) => {
			this.participantUpdated$.next(event);
		});

		this.call.on("participant-left", (event: any) => {
			this.participantLeft$.next(event);
		});
	}

	joinDailyRoom(roomUrl: string) {
		this.call.join({ url: roomUrl });
		this.setEventListeners();
	}

	setEventListener(event: DailyEvent) {
		this.call.on(event, (event) => {
			this.events.next(event);
		});
	}

	getTracks(): Observable<any> {
		return this.mediaStreams.asObservable();
	}

	toggleMicrophone() {
		this.call.setLocalAudio(!this.call.localAudio());
	}
	async leave() {
		try {
			await this.call.leave();
		} catch (e) {
			console.error("Leaving failed", e);
		}
	}

	setInputDevice(input, type) {
		const deviceOptions = {
			[type]: input
		};
		this.call.setInputDevicesAsync(deviceOptions);
	}

	checkIfDailyRoom(roomTitle: string) {
		if (roomTitle.includes("sirius-daily")) {
			this.isDailyRoom$.next(true);
		} else {
			this.isDailyRoom$.next(false);
		}
	}

	isDailyRoom(): Observable<any> {
		return this.isDailyRoom$.asObservable();
	}
	// Example: setBandwidth()

	setBandwidth(width,height,frameRate) {
		this.call.setBandwidth({
			trackConstraints: { width: width, height: height, frameRate: frameRate }
		});
	}

	setFrameRate(frameRate) {
		this.call.setBandwidth({
			trackConstraints: { frameRate: frameRate }
		});
	}

	setResolution(width,height) {
		this.call.setBandwidth({
			trackConstraints: { width: width, height: height }
		});
	}

	updateInputSettings(enableSoundProcessor: boolean) {
		const state = enableSoundProcessor
			? ProcessorState.Enabled
			: ProcessorState.Disabled;
		this.call.updateInputSettings({
			audio: {
				processor: { type: state }
			}
		});
	}
}
