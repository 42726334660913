<div
	appVideoLayouts
	[ngClass]="[
		'participant-block',
		isSplitView ? splitClass(connectedUsers.length) : '',
		isSplitViewAndUserOne() && !user.isCamera && !isTeacher ? 'split-only-one-no-cam' : ''
	]"
	[class.is-portrait]="isPortrait"
	[class.no-connection]="!checkInternet"
	[class.is-mobile]="isMobile"
	*ngFor="let user of connectedUsers; trackBy: identifyUser"
	[hidden]="!isSplitView && isHideParticipant(user) && !maximizeSecondCam"
>
	<div
		class="bg-black rounded-md bg-opacity-30 participant-wrap"
		[class.split]="isSplitView"
		[class.split-only-one]="isSplitViewAndUserOne() && !isTeacher"
		(click)="doToggleMaximize(user)"
	>
		<app-emoji-icon
			@emojiFade
			*ngIf="getUserEmoji(user.id)"
			[ngClass]="[
				isSplitView ? 'displayedEmoji' : 'smallEmoji',
				!isPortrait && isMobile ? 'landscapeMob' : '',
				!isMobile ? 'notMob' : ''
			]"
			[emoji]="getUserEmoji(user.id)"
		>
		</app-emoji-icon>

		<div
			*ngIf="user?.isTeacher && isRecordingActive"
			[ngClass]="[isSplitView ? 'displayedRecording' : 'displayedRecordingSmall']"
		></div>
		<app-view-media
			*ngIf="(user.isCamera || user.isMicro) && canDisplayAppViewMedia"
			[hidden]="!user.isCamera"
			[attr.uid]="user.id"
			class="participant-media"
			[ngClass]="{
				'screen-share': (user.screenShareStream || user.mediaStream?.streamName === 'screenshare') && isSplitView
			}"
			[class.split]="isSplitView"
			[connectedUsers]="connectedUsers"
			[user]="user"
			[adaptationMode]="adaptByHostSizesAndCover"
			[mediaStream]="user.screenShareStream || user.mediaStream"
			[autoplay]="true"
			[muted]="user.self"
			[isMirrored]="isMirrored(user)"
			[isPiano]="isPiano"
			_debugName_="user.name"
		>
		</app-view-media>

		<div *ngIf="!user.isCamera && !user.hasYoutube" class="screen-saver" [class.split-only-one]="isSplitViewAndUserOne() && !isTeacher">
			<h1 class="circle">{{ getInitial(user.name) }}</h1>
		</div>

		<div *ngIf="user?.hasYoutube" class="screen-saver youtube-container" [class.split-only-one]="isSplitViewAndUserOne() && !isTeacher">
			<div class="youtube-header">
				<div class="leftCol">
					<mat-icon class="serviceIcon" svgIcon="service-icon"></mat-icon>
					<span>YouTube</span>
				</div>
			</div>
			<video-player [activePlayer]="false" [user]="user" [videoId]="user?.youTubeVideoId"></video-player>
		</div>

		<!-- new variant -->
		<ng-container *ngIf="isSplitView">
			<div></div>
			<div
				*ngIf="!user.hasYoutube"
				[ngClass]="{
					'user-name-panel-split': updatePanelSplit,
					'hide-split-panel-info': (!isSplitView && !isHideSelf(user)) || !updatePanelSplit
				}"
			>
				<div *ngIf="user.mediaStream && user.isMicro" class="user-name-item">
					<app-volume-meter-mini [mediaStream]="user.mediaStream"></app-volume-meter-mini>
				</div>
				<img *ngIf="!user.isMicro" class="user-name-item" src="assets/icons/sirius/MicrophoneOffMini.svg" alt="" />
				<img *ngIf="!user.isCamera" class="user-name-item hide-on-mobile" src="assets/icons/sirius/CameraOffMini.svg" alt="" />
				<div class="user-name-item">{{ user.name }}{{ isHideSelf(user) && !isMobile ? ("call-room.you" | translate) : "" }}</div>
			</div>
			<app-user-actions
				[ngClass]="{ 'youtube-actions': user?.hasYoutube }"
				[isYoutube]="user?.hasYoutube"
				[isMobile]="isMobile"
				[isSelfUser]="isHideSelf(user)"
				[user]="user"
				[teacherName]="selfUser().name"
				[isFullscreen]="isFullscreen"
				appearance="vertical"
				(toggleStopYouTube)="doToggleStopYouTube()"
				(toggleMaximize)="doToggleMaximize(user)"
				(toggleFullscreen)="doToggleFullscreen(user)"
				[isMirrored]="isMirrored(user)"
			>
			</app-user-actions>
		</ng-container>

		<!-- old variant
      <div class="user-name-split" [hidden]="!isSplitView || isHideSelf(user)">
        <div class="ellipsis">{{ user.name }}</div>
      </div>
      < !-- Split view user actions -- >
      <div class="split-status-bar" *ngIf="isSplitView && !user.self">
        <img *ngIf="!user.isMicro" src="assets/icons/sirius/MicrophoneOffMini.svg" alt="">
        <img *ngIf="!user.isCamera" src="assets/icons/sirius/CameraOffMini.svg" alt="">
        <app-user-actions [user]="user" appearance="vertical"></app-user-actions>
      </div>
      -->
		<!-- speaker view + right collumn -->
		<div class="user-name !rounded-b-md" *ngIf="!isSplitView && updatePanelSplit" [class.selected-text]="isSelectedUserName(user)">
			<div *ngIf="user.mediaStream && user.isMicro" class="user-name-item">
				<app-volume-meter-mini [mediaStream]="user.mediaStream"></app-volume-meter-mini>
			</div>
			<img *ngIf="!user.isMicro && !user.hasYoutube" class="user-name-item" src="assets/icons/sirius/MicrophoneOffMini.svg" alt="" />
			<img
				*ngIf="!user.isCamera && !user.hasYoutube"
				class="user-name-item hide-on-mobile"
				src="assets/icons/sirius/CameraOffMini.svg"
				alt=""
			/>
			<div class="user-name-item ellipsis">
				{{ user.name }}{{ isHideSelf(user) && !isMobile ? ("call-room.you" | translate) : "" }}
			</div>
			<!-- User name user actions -->
			<div class="flex justify-end grow">
				<app-user-actions
					[isYoutube]="user?.hasYoutube"
					class="!block"
					[isSelfUser]="isHideSelf(user)"
					[ngClass]="{
						isMobile: isMobile,
						'youtube-actions youtube-actions-small': user?.hasYoutube
					}"
					[user]="user"
					[teacherName]="selfUser().name"
					[isFullscreen]="isFullscreen"
					[isMobile]="isMobile"
					[isMirrored]="isMirrored(user)"
					appearance="gorizontal"
					(toggleStopYouTube)="doToggleStopYouTube()"
					(toggleMaximize)="doToggleMaximize(user)"
					(toggleFullscreen)="doToggleFullscreen(user)"
				>
				</app-user-actions>
			</div>
		</div>
	</div>
</div>

<ng-container *ngFor="let user of connectedUsers; trackBy: identifyUser2ndStream">
	<div
		*ngIf="(user.secondStreamActive$ | async) && !isSecondCamOnMainPanel(user)"
		appVideoLayouts
		class="second-camera"
		[class.participant-block]="user.secondStreamActive$ | async"
	>
		<div
			[ngClass]="[
				isSplitView ? splitClass(connectedUsers.length) : '',
				isSplitViewAndUserOne() && !user.isCamera && !isTeacher ? 'split-only-one-no-cam' : ''
			]"
			[class.is-portrait]="isPortrait"
			[class.is-mobile]="isMobile"
		>
			<div
				*ngIf="user.mediaStreamSecondVideo && canDisplayAppViewMedia"
				class="bg-black rounded-md bg-opacity-30 participant-wrap"
				[class.split]="isSplitView"
				(click)="doToggleMaximizeSecondStream(user, true)"
			>
				<app-view-media
					[attr.uid]="user.id"
					class="participant-media"
					[ngClass]="{isSplitView}"
					[class.split]="isSplitView"
					[connectedUsers]="connectedUsers"
					[user]="user"
					[adaptationMode]="adaptByHostSizesAndCover"
					[mediaStream]="user.mediaStreamSecondVideo"
					[muted]="true"
					[isMirrored]="isMirrored(user)"
					[isPiano]="isPiano"
					_debugName_="user.name"
				>
				</app-view-media>

				<ng-container>
					<!-- <div></div> -->
					<div
						*ngIf="!user.hasYoutube"
						[ngClass]="{
							'user-name-panel-split': updatePanelSplit || !updatePanelSplit
						}"
					>
						<div class="user-name-item">
							{{ ("video-settings.second-camera" | translate) + ": " + user.name
							}}{{ isHideSelf(user) && !isMobile ? ("call-room.you" | translate) : "" }}
						</div>
					</div>
					<!-- <app-user-actions [ngClass]="{'youtube-actions': user?.hasYoutube}" [isYoutube]="user?.hasYoutube"
                        [isMobile]="isMobile" [isSelfUser]="isHideSelf(user)" [user]="user"
                        [teacherName]="selfUser().name" [isFullscreen]="isFullscreen" appearance="vertical"
                        (toggleStopYouTube)="doToggleStopYouTube()" (toggleMaximize)="doToggleMaximize(user)"
                        (toggleFullscreen)="doToggleFullscreen(user)" [isMirrored]="isMirrored(user)">
                    </app-user-actions> -->
				</ng-container>
			</div>
		</div>
	</div>
</ng-container>

<div *ngIf="isSplitViewAndUserOne() && isTeacher" class="rounded-md participant-block">
	@if (isScheduledLesson && currentStudent) {
	<app-student-info-panel [studentInfo]="currentStudent"></app-student-info-panel>
	} @else if (isScheduledLesson){
	<div class="screen-saver">
		<div class="flex text-white justify-center w-full text-xl text-center" *ngIf="isScheduledLesson">
			{{ "call-room.waiting-for-student" | translate }}...
		</div>
	</div>
	} @else {
	<div class="screen-saver">
		<div class="flex justify-center w-full">
			<div
				class="absolute flex text-sm pb-1.5 font-semibold leading-3 text-white -translate-y-full share-text"
				[ngClass]="{ ipad: isSafari && isTablet, desktop: isDesktop }"
			>
				{{ "call-room.share-link" | translate }}
			</div>
			<div class="flex border-sirius-bg border-solid border-[1px] max-w-[66%] h-8 text-xs text-offblack rounded-full">
				<div class="flex items-center pl-3 pr-2 overflow-hidden rounded-l-full select-text bg-white whitespace-nowrap">
					{{ linkText }}
				</div>
				<button
					*ngIf="!clipboardService.copySuccess"
					(click)="copyLink()"
					[attr.aria-label]="'aria-label.copy' | translate"
					class="flex items-center justify-center w-24 pl-2 pr-3 font-semibold rounded-r-full text-sirius-bg-dark bg-warm-offwhite hover:bg-warm-offwhite-2"
				>
					{{ "video-settings.copy" | translate }}
				</button>
				<div
					*ngIf="clipboardService.copySuccess"
					class="flex items-center justify-center w-24 pl-2 pr-3 font-semibold rounded-r-full text-sirius-bg-dark bg-warm-offwhite-2"
				>
					{{ "call-room.copied" | translate }}
				</div>
			</div>
		</div>
	</div>
	}
</div>
