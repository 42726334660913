// to do : work out why there are 2 booking types in the app - upper and lower case
export enum BookingType {
	TRIAL = "trial",
	SINGLE = "single",
	REPEAT = "repeat"
}

export enum BookingStatus {
    PENDING = 'pending',
    ACCEPTED = 'accepted',
    DECLINED = 'declined',
    COMPLETED = 'completed',
    CANCELED = 'canceled',
    RESCHEDULED = 'rescheduled',
    NOT_SHOW = 'not_show',
}

export type Teacher = {
	id: string;
	name: string;
	email: string;
};

export type StudentProfile = {
	instruments: {
		genre: string[];
		degree: string;
		instrument: string;
		certification: string;
		higherEducation: string;
		degreeDescription: string;
	}[];
	ageGroup: string;
	favoriteSong: string;
	notes: string;
	skillLevel: string;
};

export type Student = {
	id: string;
	name: string;
	email: string;
	subrole: number;
	timezone: string;
	studentProfile: StudentProfile;
};

export type Booking = {
	id: string;
	teacherId: number;
	studentId: number;
	roomId: number;
	title: string;
	description: string;
	targetTeacher: string;
	targetStudent: string;
	startTime: string;
	endTime: string;
	type: BookingType;
	duration: string;
	status: BookingStatus;
	cancelReason: string | null;
	rescheduleReason: string | null;
	teacher: Teacher;
	student: Student;
};

export type BookingResponse = {
	list: Booking[];
	count: number;
	page: string;
	pages: number;
}