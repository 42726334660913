<form class="" [formGroup]="deviceForm">
	<div>
		<label for="videoInput">Video Input:</label>
		<select id="videoInput" formControlName="videoInput">
			<option *ngFor="let device of videoDevices" [value]="device.deviceId">{{ device.label || 'Video Device' }}
			</option>
		</select>
	</div>

	<div>
		<label for="audioInput">Audio Input:</label>
		<select id="audioInput" formControlName="audioInput">
			<option *ngFor="let device of audioDevices" [value]="device.deviceId">{{ device.label || 'Audio Device' }}
			</option>
		</select>
	</div>


	<div>
		<label for="videoSettings">Set Video Quality</label>
		<select id="videoSettings" formControlName="videoSettings">
		  <!-- 360p options (4:3 ratio) -->
		  <option value="400_5">360p - 400 Kbps, 5 fps</option>
		  <option value="400_15">360p - 400 Kbps, 15 fps</option>
		  <option value="400_30">360p - 400 Kbps, 30 fps</option>
		  <option value="400_60">360p - 400 Kbps, 60 fps</option>
		  
		  <!-- 480p options (4:3 ratio) -->
		  <option value="800_5">480p - 800 Kbps, 5 fps</option>
		  <option value="800_15">480p - 800 Kbps, 15 fps</option>
		  <option value="800_30">480p - 800 Kbps, 30 fps</option>
		  <option value="800_60">480p - 800 Kbps, 60 fps</option>
		  
		  <!-- 720p options (4:3 ratio) -->
		  <option value="1500_5">720p - 1.5 Mbps, 5 fps</option>
		  <option value="1500_15">720p - 1.5 Mbps, 15 fps</option>
		  <option value="1500_30">720p - 1.5 Mbps, 30 fps</option>
		  <option value="1500_60">720p - 1.5 Mbps, 60 fps</option>
		  
		  <!-- 1080p options (4:3 ratio) -->
		  <option value="3000_5">1080p - 3 Mbps, 5 fps</option>
		  <option value="3000_15">1080p - 3 Mbps, 15 fps</option>
		  <option value="3000_30">1080p - 3 Mbps, 30 fps</option>
		  <option value="3000_60">1080p - 3 Mbps, 60 fps</option>
		  
		  <!-- 1440p options (4:3 ratio) -->
		  <option value="5000_5">1440p - 5 Mbps, 5 fps</option>
		  <option value="5000_15">1440p - 5 Mbps, 15 fps</option>
		  <option value="5000_30">1440p - 5 Mbps, 30 fps</option>
		  <option value="5000_60">1440p - 5 Mbps, 60 fps</option>
		</select>
	  </div>


	<label>
		<input type="checkbox" formControlName="enableSoundProcessor">
		Enable Sound Processor (Daily Noise Reduction)
	</label>


</form>