<!-- <div class="lesson-statistics-container"> -->
<!-- <h1>
		{{ "lesson-statistics.title" | translate }}
	</h1> -->
<div class="table-container">
	<div class="flex w-full align-middle button-group gap-2 flex-wrap-reverse">
		<div class="flex gap-2 header-block">
			<mat-select
				[(ngModel)]="selectedLessonType"
				class="app-select type"
				panelClass="s-mat-checkbox app-select-panel s-mat-select-panel border"
				(selectionChange)="onFilterChange()"
			>
				<mat-option *ngFor="let type of lessonTypes" [value]="type">
					<span
						[innerHTML]="'lesson-statistics.' + type | translate"
					></span>
				</mat-option>
			</mat-select>
			<div
				class="bg-indigo flex items-center justify-center text-white whitespace-nowrap py-1.5 px-4 total"
			>
				Total: {{ total }}
			</div>
		</div>

		<div class="flex gap-2 header-block">
			<mat-select
				[(ngModel)]="selectedYear"
				class="app-select year"
				panelClass="s-mat-checkbox app-select-panel s-mat-select-panel border"
				(selectionChange)="onFilterChange()"
			>
				<mat-option *ngFor="let year of years" [value]="year">
					{{ year }}
				</mat-option>
			</mat-select>
			<mat-select
				[(ngModel)]="selectedMonth"
				class="app-select"
				panelClass="s-mat-checkbox app-select-panel s-mat-select-panel border"
				(selectionChange)="onFilterChange()"
			>
				<mat-option
					*ngFor="let month of months; index as i"
					[value]="i"
				>
					{{ "months." + month | translate }}
				</mat-option>
			</mat-select>
		</div>
	</div>
	<!-- <div class="button-group md:hidden mt-2">
		<div
			class="bg-indigo text-white whitespace-nowrap py-1.5 px-4 w-[85px] h-[34.5px] flex items-center justify-center"
		>
			Total: {{ total }}
		</div>
	</div> -->
	<table class="max-sm:hidden mt-4" mat-table [dataSource]="dataSource">
		<ng-container matColumnDef="student">
			<th mat-header-cell *matHeaderCellDef>
				{{ "lesson-statistics.student" | translate }}
			</th>
			<td mat-cell *matCellDef="let lesson">{{ lesson?.student }}</td>
		</ng-container>
		<ng-container matColumnDef="date">
			<th mat-header-cell *matHeaderCellDef>
				{{ "lesson-statistics.date" | translate }}
			</th>
			<td mat-cell *matCellDef="let lesson">{{ lesson?.date }}</td>
		</ng-container>
		<ng-container matColumnDef="time">
			<th mat-header-cell *matHeaderCellDef>
				{{ "lesson-statistics.time" | translate }}
			</th>
			<td mat-cell *matCellDef="let lesson">{{ lesson?.time }}</td>
		</ng-container>
		<ng-container matColumnDef="duration">
			<th mat-header-cell *matHeaderCellDef>
				{{ "lesson-statistics.duration" | translate }}
			</th>
			<td mat-cell *matCellDef="let lesson">
				{{ lesson?.duration }}
			</td>
		</ng-container>
		<!-- <ng-container matColumnDef="studentAttendance">
			<th mat-header-cell *matHeaderCellDef>
				{{ "lesson-statistics.attendance-status" | translate }}
			</th>
			<td mat-cell *matCellDef="let lesson">
				{{ lesson?.studentAttendance | translate }}
			</td>
		</ng-container> -->
		<tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
		<tr mat-row *matRowDef="let lesson; columns: columnsToDisplay"></tr>
	</table>

	<table
		class="sm:hidden mobile-table mt-4"
		mat-table
		[dataSource]="dataSource"
	>
		<ng-container matColumnDef="student">
			<td mat-cell *matCellDef="let lesson">
				<div class="py-3">
					<div class="font-bold mb-1.5">
						{{ lesson?.student }}
					</div>
					<div class="flex gap-4">
						<div>
							<div>{{ lesson?.date }}</div>
						</div>
						<div>
							<div>{{ lesson?.time }}</div>
						</div>
						<div>
							<div>{{ lesson?.duration }}</div>
						</div>
					</div>
					<!-- <div *ngIf="lesson?.studentAttendance" class="mt-1.5">
						{{ lesson?.studentAttendance | translate }}
					</div> -->
				</div>
			</td>
		</ng-container>
		<tr mat-row *matRowDef="let lesson; columns: ['student']"></tr>
	</table>
	<div
		class="text-offblack support-message mt-8"
		[innerHTML]="'lesson-statistics.support-message' | translate | safeHtml"
	></div>
</div>
<!-- </div> -->
