<menu class="platform-menu">
	<li *showForSubroles="['teacherMatched']">
		<a [routerLink]="['/platform/lessons']" [routerLinkActive]="['is-active']">
			{{ (showLessonManagement() ? "menu.lessons" : "menu.trial-lessons") | translate }}
		</a>
	</li>

	@if (isPersonalRoom) {
		<li *accessControl="Resources.PERSONAL_ROOM">
			<a [routerLink]="['/platform/start']" [routerLinkActive]="['is-active']">{{ "menu.room" | translate }}</a>
		</li>
	}

	<li *showForSubroles="['studentMatched', 'studentExternal']">
		<a [routerLink]="['/platform/lessons']" [routerLinkActive]="['is-active']">{{ "menu.lessons" | translate }}</a>
	</li>

	<li>
		<a *showForSubroles="['teacherMatched', 'studentMatched']" [routerLink]="['/platform/chat']" [routerLinkActive]="['is-active']">
			<div class="notification-wrapper">
				{{ "menu.messages" | translate }}
				@if (unreadMessages > 0) {
					<span class="notification-circle">{{ unreadMessages }}</span>
				}
			</div>
		</a>
	</li>
	<li *accessControl="Resources.AVAILABILITY">
		<a [routerLink]="['/platform/availability']" [routerLinkActive]="['is-active']">{{ "availability.menu-title" | translate }}</a>
	</li>
	<ng-container *ngIf="showLessonManagement()">
		<li *accessControl="Resources.STUDENTS_LIST">
			<a [routerLink]="['/platform/students']" [routerLinkActive]="['is-active']">
				{{ "menu.students" | translate }}
			</a>
		</li>
	</ng-container>
	<ng-container *ngIf="showLessonOverview">
		<li *accessControl="Resources.LESSON_OVERVIEW">
			<a target="_blank" href="{{ lessonOverviewUrl }}">
				{{ "menu.lesson-overview" | translate }}
			</a>
		</li>
	</ng-container>

	<li *showForSubroles="['teacherSelf', 'teacherMatched', 'studentMatched']">
		<a [routerLink]="['/platform/recordings']" [routerLinkActive]="['is-active']">{{ "recordings.menu-title" | translate }}</a>
	</li>

	<ng-container *ngIf="!isAdmin">
		<li *accessControl="Resources.SUBSCRIPTION_UPGRADE">
			<a [routerLink]="['/platform/subscription']" [routerLinkActive]="['is-active']">
				{{ "menu.subscription" | translate }}
			</a>
		</li>
	</ng-container>

	<li *showForSubroles="['studentMatched']">
		<a [routerLink]="['/platform/notes']">{{ "menu.notes" | translate }}</a>
	</li>
	<li *accessControl="Resources.PRACTICE_ROOM">
		<a [routerLink]="['/practiceroom']">{{ "menu.practice-room" | translate }}</a>
	</li>
	<li *showForSubroles="['teacherMatched', 'studentMatched']">
		<a [routerLink]="['/platform/cloud']" [routerLinkActive]="['is-active']">{{ "menu.material" | translate }}</a>
	</li>
	<li>
		<a [routerLink]="['/platform/profile']" [routerLinkActiveOptions]="{ exact: false }" [routerLinkActive]="['is-active']">
			{{ "menu.profile" | translate }}
		</a>
	</li>
	<li *accessControl="Resources.SUPPORT">
		<a href="https://support.sirius.video/" target="_blank">{{ "header.support-center" | translate }}</a>
	</li>

	<li>
		<a class="logout-link" (click)="logOut()">{{ "buttons.logout" | translate }}</a>
	</li>

	<li *accessControl="Resources.REFERRAL" class="referral-link">
		<a [routerLink]="['/platform/referral']">{{
			(isMatchedTeacher ? "menu.referral-teacher" : "menu.referral-student") | translate
		}}</a>
	</li>
</menu>
