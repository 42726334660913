import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { PlatformModule } from '@angular/cdk/platform';
import {  MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';
import { VolumeMeterModule } from 'src/app/lib-visual/components/volume-meter/volume-meter.module';
import { VideoStreamDisplayModule } from 'src/app/lib-visual/components/video-stream-display/video-stream-display.module';
import { SettingPopupComponent } from './setting-popup.component';

@NgModule({
    declarations: [SettingPopupComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        PlatformModule,
        MatCardModule,
        MatDialogModule,
        MatIconModule,
        MatSelectModule,
        MatTabsModule,
        TranslateModule,
        VideoStreamDisplayModule,
        VolumeMeterModule
    ],
    exports: [SettingPopupComponent]
})
export class SettingPopupModule { }
