import { Component, OnInit } from "@angular/core";
import { StudentsService, Student, ActivationStatus } from "./students.service";
import { AuthenticationService } from "../services/authentication/authentication.service";
import { PopupService } from "../services/popup/popup.service";
import { TranslateService } from "@ngx-translate/core";
import { ProfileService } from "../profile/profile.service";
import { allowContextMenu } from "@fullcalendar/core/internal";
import { merge, forkJoin, of } from "rxjs";
import { mergeMap, map, catchError } from "rxjs/operators";
import { LessonsService } from "../services/lessons/lessons.service";

interface StudentListItem extends Student {
	isEmailSent: boolean;
	displayEmailSentMessage: boolean;
}

@Component({
	selector: "app-students",
	templateUrl: "./students.component.html",
	styleUrls: ["./students.component.scss"]
})
export class StudentsComponent implements OnInit {
	students: StudentListItem[] = [];
	activeEditor: Student["id"] | null = null;
	userId: string;
	isLoading = false;
	size: number;
	currentSize: number = 10;
	page: string = "1";
	limit = "10";
	allowExternalStudents: boolean = false;
	profile;

	constructor(
		private studentsService: StudentsService,
		private authenticationService: AuthenticationService,
		private popupService: PopupService,
		private translateService: TranslateService,
		private profileSevice: ProfileService,
		private lessonsService: LessonsService
	) {}

	ngOnInit() {
		this.init();
	}

	init() {
		this.userId = this.authenticationService.profileInfo().userId;

		this.profileSevice.get().subscribe((profile) => {
			this.profile = profile;
			this.allowExternalStudents = profile.allowExternals;
		});

		this.studentsService
			.getStudents(this.userId, this.page, this.limit)
			.pipe(
				mergeMap((data: any) => {
					const { list } = data;
					const lessonPackageObservables = list.map((student: any) =>
						this.lessonsService
							.getLessonPackageByStudentEmail(student.email)
							.pipe(
								map((lessonPackage: any) => ({
									...student,
									lessonPackageStatus: lessonPackage.active
								})),
								catchError((error) => {
									console.error(
										`Failed to get lesson package for ${student.email}`,
										error
									);
									return of({
										...student,
										lessonPackageStatus: false
									});
								})
							)
					);
					return forkJoin(lessonPackageObservables).pipe(
						map((studentsWithPackages: any[]) => {
							const filteredStudents =
								studentsWithPackages.filter(
									(student) =>
										student.lessonPackageStatus === true
								);
							return {
								...data,
								list: filteredStudents
							};
						})
					);
				})
			)
			.subscribe(
				(data) => {
					const { count, list } = data;
					this.students = list;
					this.size = count;
					this.isLoading = false;
				},
				(error) => {
					this.isLoading = false;
					if (error.status === 404) {
						this.students = null;
					}
				}
			);
	}
	openEditor(student: StudentListItem): void {
		this.activeEditor = student.id;
	}

	closeEditor(): void {
		this.activeEditor = null;
	}

	isEditorOpen(student: StudentListItem): boolean {
		return this.activeEditor === student.id;
	}

	get isStudents(): boolean {
		return this.students && this.students.length > 0;
	}

	public inviteExternalStudent() {
		this.popupService.openInviteStudenDialog(() => this.init());
	}

	public returnActivityStatus(status: string) {
		switch (status) {
			case ActivationStatus.ACTIVATED:
				return this.translateService.instant("lessons.activeStatus");
			case ActivationStatus.PENDING:
				return this.translateService.instant("lessons.pendingStatus");
			default:
				return "No status available";
		}
	}

	pageChanged(event) {
		let pageIndex = event.pageIndex + 1;
		let pageSize = event.pageSize;
		let calcSize = event.pageIndex || 1;
		let previousSize = pageSize * calcSize;
		this.getNextData(
			previousSize,
			pageIndex.toString(),
			pageSize.toString()
		);
	}

	getNextData(currentSize: number, page: string, limit: string) {
		this.currentSize = currentSize;
		this.page = page;
		this.limit = limit;
		this.studentsService.getStudents(this.userId, page, limit).subscribe(
			(data) => {
				const { count, list } = data;
				this.students = list;
				this.size = count;
				this.isLoading = false;
			},
			(error) => {
				this.isLoading = false;
				if (error.status === 404) {
					this.students = null;
				}
			}
		);
	}

	public returnColor(status: string) {
		switch (status) {
			case ActivationStatus.ACTIVATED:
				return "green";
			case ActivationStatus.PENDING:
				return "#D3A628";
			default:
				return "black";
		}
	}

	isActiveStudent(student: StudentListItem) {
		return student.activationStatus === ActivationStatus.ACTIVATED;
	}

	isPendingStudent(student: StudentListItem) {
		return student.activationStatus === ActivationStatus.PENDING;
	}

	sendEmail(student: StudentListItem) {
		student.isEmailSent = true;
		this.studentsService.sendActivationEmail(student.email).subscribe(
			(res) => {
				this.displayEmailSentMessage(student);
			},
			() => {
				student.isEmailSent = false;
			}
		);
	}

	displayEmailSentMessage(student: StudentListItem) {
		student.displayEmailSentMessage = true;
		setTimeout(() => {
			student.displayEmailSentMessage = false;
		}, 3000);
	}
}
