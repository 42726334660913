import { Component, ViewChild } from "@angular/core";
import { LessonsService } from "../services/lessons/lessons.service";
import { ProfileService } from "../profile/profile.service";
import { Profile } from "../types/profile.types";
import { catchError, flatMap, map, switchMap } from "rxjs/operators";
import { forkJoin, of } from "rxjs";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { QualityRatingService } from "../lm-quality-rating/services/quality-rating.service";
import { STUDENT_ATTENDANCES } from "../lm-quality-rating/lm-quality-rating.constants";
@Component({
	selector: "app-lesson-statistics",
	templateUrl: "./lesson-statistics.component.html",
	styleUrls: ["./lesson-statistics.component.scss"],
})
export class LessonStatisticsComponent {
	public dataSource = new MatTableDataSource<any>();
	public total: number;
	public columnsToDisplay = [
		"student",
		"date",
		"time",
		"duration"
	];
	public lessonsList = [];
	public months = [
		"january",
		"february",
		"march",
		"april",
		"may",
		"june",
		"july",
		"august",
		"september",
		"october",
		"november",
		"december",
	];
	public years = [2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030];
	public selectedMonth: number;
	public selectedYear: number;
	public lessonTypes = ["trial", "single"];
	public selectedLessonType: "trial" | "single" = "trial";
	private userId: string;
	private subrole: number;
	private timeZone: string;

	@ViewChild(MatPaginator) paginator: any;
	constructor(
		private lessonService: LessonsService,
		private profile: ProfileService,
		private qualityRatingService: QualityRatingService
	) {
		const date = new Date();
		this.selectedMonth = date.getMonth();
		this.selectedYear = date.getFullYear();
	}

	ngOnInit(): void {
		this.profile.get().subscribe((profile: Profile) => {
			this.userId = profile.userId;
			this.subrole = profile.subrole;
			this.timeZone = profile.timezone;
			this.initLessonsList();
		});
	}

	initLessonsList(): void {
		function getLocalTimeFromTimeStamp(
			timestamp: string,
			timeZone: string
		) {
			const date = new Date(timestamp);
			const localTime = new Intl.DateTimeFormat("en-US", {
				timeZone: timeZone,
				hour: "2-digit",
				minute: "2-digit",
				hour12: false,
			}).format(date);
			return localTime;
		}

		function getDateFromTimeStamp(timestamp: string, timeZone: string) {
			const date = new Date(timestamp);
			const localDate = new Intl.DateTimeFormat("en-UK", {
				timeZone: timeZone,
				day: "2-digit",
				month: "2-digit",
				year: "numeric",
			}).format(date);
			return localDate.replace(/\//g, ".");
		}

		function getAttendanceTitle(attendance: string) {
			const attendanceItem = STUDENT_ATTENDANCES.find(
				(item) => item.value === attendance
			);
			return attendanceItem ? attendanceItem.title : attendance;
		}

		this.lessonService
			.getPastBookingsByMonth(
				this.userId,
				"1",
				"1000",
				this.selectedMonth,
				this.selectedYear
			)
			.pipe(
				switchMap((responseList) => {

					const { list } = responseList; // bookings list
					const filteredList = list.filter(
						(item) =>
							item.type === this.selectedLessonType &&
							item.status === "completed"
					);
					this.total = filteredList.length;

					if (filteredList.length === 0) {
						return of([]);
					}

					const requests = filteredList.map((item) => {
						return this.qualityRatingService
							.getLessonFeedback(item.id)
							.pipe(
								map((requestData) => {
									return {
										...item,
										studentAttendance:
											requestData["studentAttendance"],
									};
								}),
								catchError((error) => {
									return of({
										...item,
										studentAttendance: null,
									});
								})
							);
					});

					return forkJoin(requests);
				})
			)
			.subscribe((mergedList) => {
				this.lessonsList = mergedList
					.filter((item) => item !== null) // Filter out any null values resulting from errors in the requests
					.map((item) => ({
						student: item.student.name,
						date: getDateFromTimeStamp(
							item.startTime,
							this.timeZone
						),
						time: getLocalTimeFromTimeStamp(
							item.startTime,
							this.timeZone
						),
						duration: item.duration,
						studentAttendance: getAttendanceTitle(
							item.studentAttendance
						),
					}));
				this.dataSource.data = this.lessonsList;

				this.dataSource.paginator = this.paginator;
			});
	}

	onFilterChange(): void {
		this.initLessonsList();
	}
}
