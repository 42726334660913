import {
	ChangeDetectorRef,
	Component,
	Injector,
	OnDestroy,
	OnInit
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { RtcStreamViewModel } from "../service/viev-model/rtc-stream-view-model";
import {
	AFFECT,
	DEMAND,
	ERROR,
	GONE,
	JOIN,
	KICK,
	NOTIFY,
	RECORDING,
	ROOM,
	TURN,
	WAITING,
	WebSocketService
} from "../../../services/ws/web-socket.service";
import {
	EventSocketService,
	ExchangeData,
	SOCKET_EVENT
} from "../../../services/ws/event-socket.service";
import { PopupService } from "../../../services/popup/popup.service";
import { CallRoomComponent, OFF } from "./call-room-component";
import { MainVideoDecorator } from "../service/main-video-decorator.service";
import { CallStorage } from "../service/storage/call-storage.service";
import { ChatService } from "../../chat/services/chat.service";
import { PlatformDetectorService } from "../../../services/platform-detector/platform-detector.service";
import { LoggingService } from "../../../services/logging/logging.service";
import { ErrorHandlerService } from "../../../services/error-handler/error-handler.service";
import { CallRoom, Room } from "./call-room";
import { RoomDataParserService } from "../../../services/ws/room-data-parser.service";
import { TranslateService } from "@ngx-translate/core";
import { AppointmentService } from "../../appointment-management/services/appointment.service";
import { RoleService } from "../../../services/role.service";
import { RoomStateStorage } from "../service/storage/RoomStateStorage";
import { RoomAuthService } from "../../../services/ws/room-auth.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { PlatformService } from "../../../platform/platform.service";
import { CustomThemeService } from "../../../services/custom-theme.service";
import { UsersService } from "../../../services/users.service";
import { StreamSettingsStorage } from "../service/storage/rtc-storage.service";
import { VideoService } from "../../../video/video.service";
import { MediaSettingsStorageService } from "../service/storage/media-settings-storage.service";
import { logger } from "src/app/lib-core/logger";
import { StorageUtil } from "src/app/utils/storage.util";
import { UserVOInfo } from "src/app/services/data/UserInfoVO";
import { Subject, Subscription } from "rxjs";
import { MediaDevicesService } from "src/app/lib-media-devices/services/media-devices.service";
import { EasyRtcService } from "src/app/lib-rtc/services/easy-rtc.service";
import { LocationUtil } from "src/app/lib-core/utils/location.util";
import { ProfileService } from "src/app/profile/profile.service";
import { LanguageService } from "src/app/services/languages/language.service";
import { IconService } from "@shared/services/icon.service";
import { CallRoomService } from "../service/call-room-service";
import { ADAPTATION_MODE } from "../../view-media/view-media.constants";
import { USER_KEY } from "../../../constants/localstorage-constants";
import { ViewMediaService } from "src/app/components/view-media/view-media.service";
import { EmojiService } from "src/app/emoji/emoji.service";
import { PianoService } from "src/app/piano/service/piano.service";
import { emojiFade } from "src/app/emoji/emoji.animations";
import { SpeakerTimeDetectorService } from "../../../services/speaker-time-detector.service";
import { BotService } from "../../../services/bot/bot.service";
import { RecordingService } from "../../../services/recording/recording.service";
import { RecordingState } from "../../../enums/recording-state";
import { first, takeUntil } from "rxjs/operators";
import { ensureGlobalObject } from "../../../services/utils";
import { PlayerStateService } from "../../../services/youtube/player-state.service";
import { MirroringService } from "../service/mirroring.service";
import { NotesService } from "src/app/services/notes/notes.service";
import { LessonsService } from "src/app/services/lessons/lessons.service";
import { MixpanelService } from "src/app/services/mixpanel/mixpanel.service";
import { RoomService } from "src/app/services/room.service";
import { AudioContextService } from "src/app/services/audio-context/audio-context.service";
import { TalkServiceService } from "src/app/talkjs-chat/services/talk-service.service";
// import { AccessControlService } from "src/app/services/access-control/access-control.service";
import { DailyRtcService } from "src/app/lib-rtc/services/daily-rtc.service";
import { IntercomService } from "src/app/services/intercom/intercom.service";
import { CallSiriusCloudService } from "../../call-sirius-cloud/call-sirius-cloud.service";

@Component({
	selector: "app-call-room",
	templateUrl: "../call-room.component.html",
	styleUrls: ["../call-room.component.scss"],
	providers: [MainVideoDecorator, RoleService],
	animations: [emojiFade]
})
export class PublicCallRoomComponent
	extends CallRoomComponent
	implements OnInit, CallRoom, OnDestroy
{
	private accessStatusSub: Subscription | null = null;
	constructor(
		private router: Router,
		protected changeDetector: ChangeDetectorRef,
		private injector: Injector,
		private route: ActivatedRoute,
		private storage: CallStorage, // global
		public roomState: RoomStateStorage,
		protected webSocketService: WebSocketService,
		protected eventSocketService: EventSocketService,
		private mainVideoDecorator: MainVideoDecorator,
		protected rtcStreamViewModel: RtcStreamViewModel,
		private appointmentService: AppointmentService,
		protected roomDataParserService: RoomDataParserService,
		protected popupService: PopupService,
		protected chatService: ChatService,
		protected notesService: NotesService,
		protected loggingService: LoggingService,
		protected platformDetectorService: PlatformDetectorService,
		protected errorHandlerService: ErrorHandlerService,
		protected translateService: TranslateService,
		private authService: RoomAuthService,
		protected authenticationService: AuthenticationService,
		public platformService: PlatformService,
		public customThemeService: CustomThemeService,
		protected usersService: UsersService,
		protected settingsStorage: StreamSettingsStorage,
		protected videoService: VideoService,
		protected roomAuthService: RoomAuthService,
		protected mediaDevicesService: MediaDevicesService,
		protected easyRtcService: EasyRtcService,
		protected mediaSettingsService: MediaSettingsStorageService,
		public profileService: ProfileService,
		public callRoomService: CallRoomService,
		public languageService: LanguageService,
		public iconService: IconService,
		private title: Title,
		private viewMediaService: ViewMediaService,
		public emojiService: EmojiService,
		public pianoService: PianoService,
		public speakerTimeDetectorService: SpeakerTimeDetectorService,
		public botService: BotService,
		public recording: RecordingService,
		public playerStateService: PlayerStateService,
		public mirroringService: MirroringService,
		public lessonService: LessonsService,
		public talkService: TalkServiceService,
		protected mixpanelService: MixpanelService,
		public roomService: RoomService,
		protected audioContextService: AudioContextService,
		private dailyRtcService: DailyRtcService,
		public intercom: IntercomService,
		public callCloud: CallSiriusCloudService
	) {
		super(
			router,
			changeDetector,
			storage,
			roomState,
			rtcStreamViewModel,
			injector,
			popupService,
			chatService,
			notesService,
			loggingService,
			platformDetectorService,
			webSocketService,
			eventSocketService,
			translateService,
			authenticationService,
			customThemeService,
			usersService,
			roomDataParserService,
			settingsStorage,
			videoService,
			roomAuthService,
			mediaDevicesService,
			easyRtcService,
			mediaSettingsService,
			profileService,
			languageService,
			platformService,
			iconService,
			callRoomService,
			emojiService,
			speakerTimeDetectorService,
			botService,
			recording,
			playerStateService,
			mirroringService,
			lessonService,
			// accessControlService
			mixpanelService,
			roomService,
			audioContextService,
			talkService,
			intercom,
			callCloud
		);
	}
	// todo add life cycle
	protected isCrutchSolution: boolean;
	public currentVideoWidth: string;
	public isJoin: boolean = false;
	public adaptByHostSizesAndCover =
		"adapt-by-host-sizes-and-cover" as ADAPTATION_MODE;
	public recordingState: RecordingState;
	public shouldHideRecordingNotification = false;
	public isDailyRoom$;
	isDailyRoom: boolean | undefined;

	dailyUrl = "https://sirius-app.daily.co/FvYV6g1uuWCDjxnbMGGh";

	get canDisplayAppViewMedia(): boolean {
		return (
			this.authService.isAuth() &&
			this.route.snapshot.paramMap.get("roomid") ===
				StorageUtil.readRoomIdToSessionStorage()
		);
	}

	get canBeShowAppViewMediaForAndroid(): boolean {
		if (!this.platformDetectorService.isAndroid()) {
			return true;
		}
		return this.platformDetectorService.isAndroid() && !this.isSplitView;
	}

	ngOnInit(): Promise<void> {
		this.route.queryParams.subscribe((param) => {
			if (param.userId) {
				localStorage.setItem("userId", param.userId);
			}
		});
		super.ngOnInit();
		ensureGlobalObject("APP.conference").membersCount = 1;
		this.platformService.hasEnteredRoom = true;
		this.roomName = this.callStorage.title;
		const roomId = this.route.snapshot.paramMap.get("roomid");

		// Check if the room is a daily room
		this.dailyRtcService.checkIfDailyRoom(this.roomName);
		this.isDailyRoom$ = this.dailyRtcService.isDailyRoom$;
		this.dailyRtcService.isDailyRoom$.subscribe((value) => {
			this.isDailyRoom = value;
		});

		this.title.setTitle("/" + roomId + " - Sirius");
		this.callStorage.roomId = roomId;
		this.callStorage.role = "1a";
		this.chatService.setRoomID(this.callStorage.roomId);
		if (!!this.accessStatusSub) {
			this.accessStatusSub.unsubscribe();
		}
		this.accessStatusSub = this.callStorage.accessStatus$.subscribe(
			(status) => {
				if (status === "denied") {
					this.popupService.closeLetInWaitingDialog();
					this.popupService.openAccessDeniedDialog();
				} else if (status === "pending") {
					this.popupService.openLetInWaitingDialog();
					const roomId = this.route.snapshot.paramMap.get("roomid");
					this.webSocketService.dispatch(WAITING, { roomId: roomId });
				} else if (status === "empty") {
					this.popupService.openLetInWaitingDialog();
					const roomId = this.route.snapshot.paramMap.get("roomid");
					this.webSocketService.dispatch(WAITING, { roomId: roomId });
				} else if (status === "roomFull") {
					this.popupService.closeLetInWaitingDialog();
					const userName = sessionStorage.getItem(USER_KEY);
					this.eventSocketService.notify(this.callStorage.roomId, {
						roomFull: true,
						name: userName
					});
					this.popupService.openRoomFullDialog();
				} else {
					this.popupService.closeLetInWaitingDialog();
				}

				this.roomState.isLoading = false;
			}
		);
		const previousRoomId = StorageUtil.readRoomIdToSessionStorage();
		if (this.authService.isAuth() && roomId !== previousRoomId) {
			this.doFinishCall(false, false);
		}
		if (!this.roomState.isTeacher) {
			this.recordingService
				.checkRecordingStatus(roomId)
				.subscribe((status) => {
					if (status && status.isRecording) {
						this.handleRecordingState(RecordingState.recording);
					}
				});
		}
		if (BotService.isBot) {
			const userName = BotService.userName;
			this.registerBotDataAndCheckJoinPossibility(userName);
			return;
		}
		if (this.authService.isAuth() && roomId === previousRoomId) {
			this.joinRoom();
		} else {
			const popup = this.popupService.openLobbyPopup(
				this.callStorage.serverData
			); // .then(() => { this.joinRoom(); });
			popup.afterClosed().subscribe(async (join) => {
				if (!!join) {
					this.bgColor = localStorage.getItem("bgColor") || "#162329"; // @todo: move to configuration
					this.joinRoom();
				} else if (false === join) {
					let redirectTo = ["/"];
					if (this.authenticationService.accessToken) {
						redirectTo = ["platform", "start"];
					}
					this.router.navigate(redirectTo).finally(() => {
						if (this.roomState.isLoading) {
							this.roomState.isLoading = false;
						}
					});
				}
			});
		}
		this.route.queryParams.subscribe((param) => {
			if (param.ctr) {
				localStorage.setItem("ctr", param.ctr);
			}
			if (param.aff) {
				localStorage.setItem("aff", param.aff);
			}
		});
		return Promise.resolve();
	}

	async registerBotDataAndCheckJoinPossibility(userName: string) {
		sessionStorage.setItem(USER_KEY, userName);
		window.localStorage.setItem("last_username", userName);
		this.enableCamAndMicByDefault();
		this.webSocketService.subscribe(ROOM, this.onSocketEvent);
		this.webSocketService.dispatch(TURN, {
			option: DEMAND,
			name: BotService.userName,
			status: true,
			recordingBot: BotService.skipPreJoin
		});
		this.init(this.callStorage.roomId, this.usersService.selfUser.id);
		this.chatService.clearChatMessages();
		this.addObservers();
		this.authService.joinRoom(this.userId).subscribe((joinResult) => {
			if (!!joinResult && joinResult.status) {
				const roomId = this.route.snapshot.paramMap.get("roomid");
				StorageUtil.writeRoomIdToSessionStorage(roomId);
			}
		});
	}

	private enableCamAndMicByDefault() {
		this.roomState.isCamOnImSure = this.roomState.isCamOn;
		StorageUtil.writeMediaPrmsToSessionStorage({
			isCam: this.roomState.isCamOn
		});
		this.roomState.isMicroOnImSure = this.roomState.isMicroOn;
		StorageUtil.writeMediaPrmsToSessionStorage({
			isMicro: this.roomState.isMicroOn
		});
	}

	// triggers after clicking on „Enter room“
	private joinRoom() {
		// todo: Get room from booking here
		if (this.isDailyRoom) {
			this.dailyRtcService.joinDailyRoom(this.dailyUrl);
		}

		if (BotService.isBot) {
			return;
		}
		// When connecting via RTS, we indicate the user ID.
		// console.log(this.callStorage.roomId, 'this.callStorage.roomId');
		this.init(this.callStorage.roomId, this.usersService.selfUser.id);
		this.changeDetector.markForCheck();
		const savedStatusParams = StorageUtil.readMediaPrmsFromSessionStorage();
		const isVideoOff = LocationUtil.findGetParameter("video") === OFF;
		if (savedStatusParams.isCam && isVideoOff) {
			savedStatusParams.isCam = false;
		}
		const isAudioOff = LocationUtil.findGetParameter("audio") === OFF;
		if (savedStatusParams.isMicro && isAudioOff) {
			savedStatusParams.isMicro = false;
		}
		this.chatService.clearChatMessages();
		this.addObservers();
		this.authService.joinRoom(this.userId).subscribe((joinResult) => {
			// console.log(joinResult && joinResult.status, 'stest');
			if (!!joinResult && joinResult.status) {
				// set saved params
				setTimeout(() => {
					this.doToggleMicro(savedStatusParams.isMicro, false);
					this.doToggleCamera(savedStatusParams.isCam, false);
				}, 100);
				this.changeDetector.markForCheck();
				// #this.doToggleIsReflection(savedStatusParams.isReflection);
				this.isJoin = true;
				const roomId = this.route.snapshot.paramMap.get("roomid");
				StorageUtil.writeRoomIdToSessionStorage(roomId);
				// logger.log('#PublicCallRoomComponent(); webSocketService.subscribe(ROOM)');
				this.webSocketService.subscribe(ROOM, this.onSocketEvent);
			} else {
				this.doFinishCall(true, false);
			}
		});
	}

	onDestroy(): void {
		this.webSocketService.unSubscribe(ROOM, this.onSocketEvent);
		if (!!this.accessStatusSub) {
			this.accessStatusSub.unsubscribe();
		}
		super.onDestroy();
	}

	initStreamViewModel = (): Room => {
		return this.mainVideoDecorator.decorate(this);
	};

	protected notifyHandler(data) {
		if (data.roomFull) {
			this.notJoinedList.push(data);
		}
		if (data.hasEmoji) {
			this.emojiService.triggerEmoji(data);
		}
		if (data.pianoNoteData) {
			this.pianoService.triggerNote(data);
		}
		if (data.recordingState || data.resetRecording) {
			if (data.recordingState) {
				this.handleRecordingState(data.recordingState, true);
			}
			if (data.resetRecording) {
				this.recordingState = null;
				this.recordingService.recordingState$.next(null);
			}
		}
		if (data.youtube) {
			if (
				data.youtube.event === "start" &&
				!this.playerStateService.playerUser
			) {
				this.doYoutubeVideoId(data.youtube.videoId);
			}
			if (
				data.youtube.event === "stop" &&
				this.playerStateService.playerUser
			) {
				this.doYoutubeVideoId(null);
			}
			if (
				data.youtube.event === "paused" &&
				this.playerStateService.playerUser
			) {
				if (this.usersService.selfUser.id !== data.youtube.userId) {
					this.playerStateService.playerEvent.forEach((ev) => {
						ev.seekTo(data.youtube.currentTime);
						ev.pauseVideo();
					});
					this.doToggleMicro(true, false);
				}
			}
			if (
				data.youtube.event === "playing" &&
				this.playerStateService.playerUser
			) {
				if (this.usersService.selfUser.id !== data.youtube.userId) {
					this.playerStateService.playerEvent.forEach((ev) => {
						ev.playVideo();
						ev.seekTo(data.youtube.currentTime);
					});
					this.doToggleMicro(false, false);
				}
			}
			if (data.youtube.event === "enter") {
				if (data.youtube.userId) {
					if (data.youtube.userId === this.usersService.selfUser.id) {
						this.doYoutubeVideoId(data.youtube.videoId);
						const subject = new Subject();
						this.playerStateService.playerEvent$
							.pipe(takeUntil(subject))
							.subscribe((playerEvent) => {
								if (
									playerEvent &&
									playerEvent.length === 2 &&
									!this.playerStateService.isHostPlayer
								) {
									this.eventSocketService.notify(
										this.callStorage.roomId,
										{
											youtube: {
												videoId: data.youtube.videoId,
												event: "paused",
												userId: this.usersService
													.selfUser.id,
												currentTime:
													data.youtube.currentTime
											}
										}
									);
									playerEvent.forEach((ev) => {
										if (data.youtube.currentTime !== 0) {
											ev.seekTo(data.youtube.currentTime);
											setTimeout(
												() => ev.pauseVideo(),
												300
											);
										}
									});
									subject.next();
								}
							});
					}
				}
			}
		}
	}

	doPlayerOnPause(event) {
		if (this.playerStateService.isHostPlayer) {
			this.eventSocketService.notify(this.callStorage.roomId, {
				youtube: {
					videoId: event.videoId,
					event: "paused",
					userId: this.usersService.selfUser.id,
					currentTime: event.getCurrentTime()
				}
			});
			this.doToggleMicro(true, false);
		}
	}

	doPlayerOnPlay(event) {
		if (this.playerStateService.isHostPlayer) {
			this.eventSocketService.notify(this.callStorage.roomId, {
				youtube: {
					videoId: event.videoId,
					event: "playing",
					userId: this.usersService.selfUser.id,
					currentTime: event.getCurrentTime()
				}
			});
			this.doToggleMicro(false, false);
		}
	}

	private onSocketEvent = (data) => {
		// logger.log('#PublicCallRoom.event() ', data[0]);
		if (BotService.isBot) {
			console.log("Server WS response " + JSON.stringify(data[0]));
		}
		switch (data[0]._event) {
			case NOTIFY:
				this.notifyHandler(data[0]);
				break;
			case JOIN:
				this.joinHandler(data[0]);
				break;
			case TURN:
				this.usersService.updateUserVOInfoList(data[0].users);
				const raisedHandUsers = this.getRaisedHandUsers(
					this.usersService.prevUserVOInfoList,
					this.usersService.userVOInfoList
				);
				if (raisedHandUsers) {
					sessionStorage.reconnectTurn = true;
					this.audioInterruptPlay();
				}
				this.turnHandler(data[0]);
				break;
			case ERROR:
				if (!this.isCrutchSolution) {
					this.isCrutchSolution = true;
					this.eventSocketService.view(this.callStorage.roomId);
					// this.webSocketService. dispatch(VIEW, {
					//   token: this.authenticationService.accessToken,
					//   room: this.callStorage.roomId,
					//   reconnectView: sessionStorage.getItem('reconnectView') ? sessionStorage.getItem('reconnectView') : false
					// });
				} else {
					if (data[0].status === 403) {
						// For 403 we need to pass callback with finishing the call
						sessionStorage.isLive = false;
						sessionStorage.hidePassword = false;
						sessionStorage.reconnectView = false;
						sessionStorage.reconnectJoin = false;
						sessionStorage.reconnectTurn = false;
						this.errorHandlerService.processServerError(
							data[0],
							() => {
								if (!BotService.isBot) {
									this.doFinishCall(true, false);
								}
							}
						);
					} else {
						this.errorHandlerService.processServerError(data[0]);
					}
				}
				break;
			case DEMAND:
				logger.log("demand ", data[0]);
				break;
			case AFFECT:
				const affectedSelf = data[0].users.find(
					(affectedUser) =>
						affectedUser.id === this.usersService.selfUser.id
				);
				if (affectedSelf) {
					if (!affectedSelf.options.camera) {
						this.roomState.isCamOn = false;
						// this.doToggleCamera(false, true);
					}
					if (!affectedSelf.options.microphone) {
						this.roomState.isMicroOn = false;
						// this.doToggleMicro(false, true);
					}
				}
				break;
			case SOCKET_EVENT.EXCHANGE:
				const exchangeData: ExchangeData = data[0] as ExchangeData;
				if (!!exchangeData) {
					if (exchangeData.unmute) {
						this.addTeacherRequest(exchangeData.name, true, false);
					} else if (exchangeData.unhide) {
						this.addTeacherRequest(exchangeData.name, false, true);
					}
					if (exchangeData.style) {
						this.bgColor = exchangeData.style; // apply color to the room
						localStorage.setItem("bgColor", exchangeData.style);
					}
				}
				break;
			case KICK:
				if (data[0].target === this.usersService.selfUser.id) {
					this.popupService.closeAll();
					this.doFinishCall(true, false);
				}
				break;
			case RECORDING:
				if (
					data[0].status === 0 ||
					data[0].status === 3 ||
					data[0].status === 4
				) {
					this.roomState.isRecording = false;
				}
				this.roomState.recordingDisabled = data[0].status === 1;
				console.log(data[0], "===RECORDING=== ");
				switch (data[0].state) {
					/* case 1:
            // We display this message as a default on recording button click, so here it will be unused
            // this.recordingWait();   // Uncomment if we stop displaying it on recording button click
            break;
          case 2:
            this.recordingStarted();
            break;
          case 3:
            this.recordingStopped();
            break;
          case 4:
            this.recordingError();
            break;
          default: // status is 0 or > 4
            this.recordingFail();
            break; */
					case RecordingState.pending:
						this.handleRecordingState(RecordingState.pending);
						break;
					case RecordingState.recording:
						this.handleRecordingState(RecordingState.recording);
						break;
					case RecordingState.stop:
						this.handleRecordingState(RecordingState.stop);
						break;
					default:
						// recordingFail
						break;
				}
				break;
			case GONE:
				this.roomState.isRecording = data[0].recording;
				this.goneHandler(data[0]);
				break;
			// default:
			// logger.log('Public call room default event', data[0]._event, 'with data', data[0]);
			// break;
		}
	};

	private handleRecordingState(
		recordingState: RecordingState,
		ignore = false
	) {
		if (ignore && this.roomState.isTeacher) {
			return;
		}
		if (this.roomState.isTeacher) {
			this.eventSocketService.notify(this.callStorage.roomId, {
				recordingState
			});
		}
		if (
			recordingState === RecordingState.stop &&
			this.recordingState === RecordingState.recording
		) {
			this.recordingState = recordingState;
			this.recordingService.recordingState$.next(this.recordingState);
			setTimeout(() => {
				this.recordingState = null;
				this.recordingService.recordingState$.next(this.recordingState);
			}, 10000);
		}
		if (
			recordingState === RecordingState.stop &&
			this.recordingState === RecordingState.pending
		) {
			this.isRecordingTurnOn = false;
			this.recordingState = null;
		}
		if (recordingState === RecordingState.pending) {
			this.recordingState = recordingState;
			this.recordingService.recordingState$.next(this.recordingState);
		}
		if (recordingState === RecordingState.recording) {
			this.shouldHideRecordingNotification = false;
			this.recordingState = recordingState;
			this.recordingService.recordingState$.next(this.recordingState);
			setTimeout(
				() => (this.shouldHideRecordingNotification = true),
				2000
			);
		}
	}

	private getRaisedHandUsers(
		prevUserVOInfoList: UserVOInfo[],
		currUserVOInfoList: UserVOInfo[]
	): boolean {
		let result = false;
		if (prevUserVOInfoList.length > 0 && currUserVOInfoList.length > 0) {
			for (
				let idx = 0;
				!result && idx < currUserVOInfoList.length;
				idx++
			) {
				const userVOInfo = currUserVOInfoList[idx];
				const prevUserVOInfo = prevUserVOInfoList.find(
					(item) => item.id === userVOInfo.id
				);
				result =
					!!prevUserVOInfo &&
					prevUserVOInfo.options.risehand !==
						userVOInfo.options.risehand;
			}
		}
		return result;
	}
}
