<header class="header" [class.show-user-menu]="userMenuVisible">
	<div class="beta-info-text" [hidden]="!isBetaInfoDisplayed">
		{{ "start.info-text-3" | translate }}
		<button class="upgrade-now" (click)="setTariffPro()">
			{{ "start.upgrade-btn" | translate }}
		</button>
	</div>
	<div class="header-menu-row">
		<div class="logo-outer">
			<a (click)="logoClick()">
				<img *ngIf="!(customThemeService.logoURL$ | async)" class="logo" src="/assets/icons/sirius/Sirius.png" alt="Sirius" />
				<img *ngIf="customThemeService.logoURL$ | async" class="logo" src="{{ customThemeService.logoURL$ | async }}" alt="" />
			</a>
		</div>
		<app-language-selector *ngIf="!isUserLogged"></app-language-selector>
		<div class="actions"></div>
		<div class="actions">
			<ng-container *hideForSubroles="['teacherMatched', 'studentMatched']">
				<a
					*ngIf="!isAdmin && !isSchool && !(authenticationService.isLoggedIn$ | async)"
					class="default-ui-button header-link"
					routerLink="/"
					fragment="features"
				>
					{{ "header.features" | translate }}
				</a>
				<a *ngIf="!isAdmin" class="default-ui-button header-link" href="https://support.sirius.video/" target="_blank">
					{{ "header.support-center" | translate }}
				</a>
				<button *ngIf="!isAdmin && !isSchool" class="default-ui-button transparent-ui-button" [routerLink]="['/pricing']">
					{{ "header.subscription" | translate }}
				</button>
				<div class="actions" *ngIf="!(authenticationService.isLoggedIn$ | async)">
					<button class="default-ui-button transparent-ui-button" (click)="loginClick()">
						{{ "buttons.login" | translate }}
					</button>
					<button *ngIf="!isAdmin && !isSchool" class="default-ui-button blue-ui-button" (click)="signUpClick()">
						{{ "buttons.start-free-now" | translate }}
					</button>
				</div>
			</ng-container>
			<div class="flex flex-row h-full">
				<div class="user-label-container" *showForSubroles="['studentMatched', 'teacherMatched']">
					<a routerLink="/platform/referral" class="referral-icon"></a>
				</div>
				<div class="user-label-container" (mouseleave)="hideUserMenuDesktop()">
					<div
						class="user-label"
						[ngClass]="{
							loggedIn: authenticationService.isLoggedIn$ | async
						}"
						(mouseenter)="showUserMenuDesktop()"
						(click)="toggleUserMenuMobile()"
					>
						<mat-icon class="scale-150">more_vert</mat-icon>
					</div>
				</div>
			</div>
		</div>
	</div>
</header>

<menu
	class="user-menu"
	(mouseenter)="showUserMenuDesktop()"
	(mouseleave)="hideUserMenuDesktop()"
	(click)="hideUserMenu()"
	[ngClass]="{ dark: menuStyle === 'dark', light: menuStyle === 'light' }"
>
	<ng-container *ngIf="authenticationService.isLoggedIn$ | async">
		<li *showForSubroles="['teacherMatched']">
			<a [routerLink]="['/platform/lessons']" [routerLinkActive]="['is-active']">
				{{ (showLessonManagement() ? "menu.lessons" : "menu.trial-lessons") | translate }}
			</a>
		</li>
		<li *showForSubroles="['studentMatched', 'studentExternal']">
			<a [routerLink]="['/platform/lessons']" [routerLinkActive]="['is-active']">{{ "menu.lessons" | translate }}</a>
		</li>

		@if (!isAdmin && isPersonalRoom) {
			<li *accessControl="Resources.PERSONAL_ROOM">
				<a routerLink="/platform/start">{{ "menu.room" | translate }}</a>
			</li>
		}
		<li>
			<a *showForSubroles="['teacherMatched', 'studentMatched']" [routerLink]="['/platform/chat']" [routerLinkActive]="['is-active']">
				{{ "menu.messages" | translate }}
			</a>
		</li>
		<li *accessControl="Resources.AVAILABILITY">
			<a [routerLink]="['/platform/availability']" [routerLinkActive]="['is-active']">
				{{ "availability.menu-title" | translate }}
			</a>
		</li>
		<li *accessControl="Resources.STUDENTS_LIST">
			<a [routerLink]="['/platform/students']" [routerLinkActive]="['is-active']">
				{{ "menu.students" | translate }}
			</a>
		</li>
		<ng-container *ngIf="showLessonOverview">
			<li *accessControl="Resources.LESSON_OVERVIEW">
				<a target="_blank" href="{{ lessonOverviewUrl }}">
					{{ "menu.lesson-overview" | translate }}
				</a>
			</li>
		</ng-container>
		<ng-container *ngIf="!isAdmin">
			<li *showForSubroles="['teacherSelf', 'teacherMatched', 'studentMatched']">
				<a [routerLink]="['/platform/recordings']">{{ "recordings.menu-title" | translate }}</a>
			</li>
		</ng-container>
		<ng-container *ngIf="!isAdmin">
			<li *accessControl="Resources.SUBSCRIPTION_UPGRADE" class="navigation-links">
				<a routerLink="/platform/subscription">{{ "menu.subscription" | translate }}</a>
			</li>
		</ng-container>
		<li *showForSubroles="['studentMatched']">
			<a [routerLink]="['/platform/notes']">
				{{ "menu.notes" | translate }}
			</a>
		</li>
		<li *accessControl="Resources.PRACTICE_ROOM">
			<a [routerLink]="['/practiceroom']">
				{{ "menu.practice-room" | translate }}
			</a>
		</li>
		<li *showForSubroles="['teacherMatched', 'studentMatched']">
			<a [routerLink]="['/platform/cloud']" [routerLinkActive]="['is-active']">{{ "menu.material" | translate }}</a>
		</li>
		<li *ngIf="!isAdmin">
			<a routerLink="/platform/profile/account">{{ "header.menu.profile" | translate }}</a>
		</li>
		<li *accessControl="Resources.SUPPORT" class="navigation-links">
			<a href="https://support.sirius.video/" target="_blank">{{ "header.support-center" | translate }}</a>
		</li>
		<li>
			<a (click)="logOut()">{{ "buttons.logout" | translate }}</a>
		</li>
		<li *accessControl="Resources.REFERRAL">
			<a class="referral-link" routerLink="/platform/referral">{{
				(isMatchedTeacher ? "menu.referral-teacher" : "menu.referral-student") | translate
			}}</a>
		</li>
	</ng-container>
</menu>
